<template>
  <div class="block mt-2">
    <div v-if="agent.type === 'Calling'">
      <CallingHero />
    </div>
    <div v-if="agent.type === 'Backend'">
      <BackendHero />
    </div>
    <div
    v-if="agent.type === 'Admin'"
    >
      <BackendHero />
      <CallingHero />
    </div>
  </div>
</template>

<script>
import CallingHero from '@/components/CallingHero.vue';
import BackendHero from '@/components/BackendHero.vue';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    CallingHero,
    BackendHero,
  },
  computed: {
    ...mapState({
      agent: ({ agentData: { agent } }) => agent,
    }),
  },
};
</script>

import { baseUrl } from '@/common';

const testsView = {
  state: () => ({
    tests: [],
    page: 1,
    filter: null,
    sort: 'updatedAt',
    status: null,
  }),
  mutations: {
    setTests(state, tests) {
      state.tests = tests;
    },
    setTestsViewPage(state, page) {
      state.page = page;
    },
    setTestFilter(state, filter) {
      state.filter = filter;
    },
    setTestsViewSort(state, sort) {
      state.sort = sort;
    },
    setTestsViewFilterStatus(state, status) {
      state.status = status;
    },
  },
  actions: {
    setTestsViewFilter({ commit }, payload) {
      commit('setTestFilter', payload);
    },
    setTestsViewSort({ commit }, payload) {
      commit('setTestsViewSort', payload);
    },
    setTestsViewFilterStatus({ commit }, payload) {
      commit('setTestsViewFilterStatus', payload);
    },
    updateTestsViewPage({ commit, state }, payload) {
      if (payload === 'INCREMENT') {
        commit('setTestsViewPage', state.page + 1);
      } else if (payload === 'DECREMENT') {
        if (state.page > 1) {
          commit('setTestsViewPage', state.page - 1);
        } else {
          commit('setTestsViewPage', 1);
        }
      } else if (typeof payload === 'number') {
        commit('setTestsViewPage', payload);
      }
    },
    async queryTests({ state, getters, commit }) {
      const response = await fetch(
        `${baseUrl}/test?page=${state.page}&sort=${state.sort}${state.filter ? `&filter=${state.filter}` : ''}${state.status ? `&status=${state.status}` : ''}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getters.getCredentialString,
          },
        },
      );

      if (response.status === 400) {
        throw new Error('Incorrect data');
      }

      if (response.status === 200) {
        const { data: { tests } } = await response.json();
        commit('setTests', tests);
      }
    },
  },
};

export default testsView;

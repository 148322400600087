/* eslint-disable no-await-in-loop */
import { baseUrl } from '@/common';

const chargesView = {
  state: () => ({
    charges: [],
    page: 1,
    invoiceRequired: null,
    minDate: null,
    maxDate: null,
  }),
  mutations: {
    setCharges(state, charges) {
      state.charges = charges;
    },
    setChargesViewPage(state, page) {
      state.page = page;
    },
    setInvoiceRequired(state, invoiceRequired) {
      state.invoiceRequired = invoiceRequired;
    },
    setChargeInvoiceId(state, { invoiceId, chargeId, invoiceeName }) {
      const { charges: localCharges } = state;
      const chargeIndex = localCharges.findIndex((charge) => charge.id === chargeId);
      if (localCharges[chargeIndex].Invoices) {
        localCharges[chargeIndex].Invoices.push({
          id: invoiceId,
          invoiceeName,
        });
      } else {
        localCharges[chargeIndex].Invoices = [{
          id: invoiceId,
          invoiceeName,
        }];
      }
      state.charges = localCharges;
    },
    setCHMinDate(state, minDate) {
      state.minDate = minDate;
    },
    setCHMaxDate(state, maxDate) {
      state.maxDate = maxDate;
    },
  },
  actions: {
    updateChargesViewPage({ commit, state }, payload) {
      if (payload === 'INCREMENT') {
        commit('setChargesViewPage', state.page + 1);
      } else if (payload === 'DECREMENT') {
        if (state.page > 1) {
          commit('setChargesViewPage', state.page - 1);
        } else {
          commit('setChargesViewPage', 1);
        }
      } else if (typeof payload === 'number') {
        commit('setChargesViewPage', payload);
      }
    },
    setRequiredFilter({ commit }, payload) {
      commit('setInvoiceRequired', payload);
    },
    setCHMinDate({ commit }, payload) {
      commit('setCHMinDate', payload);
    },
    setCHMaxDate({ commit }, payload) {
      commit('setCHMaxDate', payload);
    },
    // async generateInvoice({ getters }, payload) {
    //   const response = await fetch(`${baseUrl}/charge/invoice/${payload}`, {
    //     method: 'POST',
    //     mode: 'cors',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: getters.getCredentialString,
    //     },
    //   });

    //   if (response.status === 400) {
    //     throw new Error('Incorrect data');
    //   }

    //   if (response.status === 200) {
    //     console.log('res', response);
    //     const blob = new Blob([response.data], { type: 'application/pdf' });
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.download = 'receipt';
    //     link.click();
    //     URL.revokeObjectURL(link.href);
    //   }
    // },
    async queryCharges({ state, getters, commit }, toDownload) {
      const response = await fetch(`${baseUrl}/charge?page=${state.page}${state.invoiceRequired ? '&required=true' : ''}${state.minDate ? `&min_date=${state.minDate}` : ''}${state.maxDate ? `&max_date=${state.maxDate}` : ''}${toDownload ? `&download=${toDownload}` : ''}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
      });

      if (response.status === 400) {
        throw new Error('Incorrect data');
      }

      if (response.status === 200) {
        const { data: { charges } } = await response.json();
        commit('setCharges', charges);
      }
    },
  },
};

export default chargesView;

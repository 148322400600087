import { baseUrl } from '@/common';

const home = {
  state: () => ({
    chartData: [],
    cardChartData: [],
    backendSummary: null,
    callingSummary: null,
    paymentChartFilter: 'week',
    cardChartFilter: 'week',
    paymentLabels: Array.from({ length: 7 }, (_, i) => new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(new Date(2021, 3, i - 3))),
    labels: [
      'Open',
      'To Be Booked',
      'First Contact',
      'Called Customer',
      'No Response',
      'Course Pending',
      'Ongoing Verification',
      'Document Verified',
      'Processing',
      'Customer Dispute',
      'CSCS Dispute',
      'Refund Request',
      'Approved',
      'Cancelled',
    ],
  }),
  mutations: {
    setChartData(state, chartData) {
      state.chartData = chartData;
    },
    setCardChartData(state, cardChartData) {
      state.cardChartData = cardChartData;
    },
    setBackendSummary(state, backendSummary) {
      state.backendSummary = backendSummary;
    },
    setCallingSummary(state, callingSummary) {
      state.callingSummary = callingSummary;
    },
    setPaymentChartFilter(state, paymentChartFilter) {
      state.paymentChartFilter = paymentChartFilter;
    },
    setCardChartFilter(state, cardChartFilter) {
      state.cardChartFilter = cardChartFilter;
    },
    setPaymentLabels(state, paymentLabels) {
      state.paymentLabels = paymentLabels;
    },
  },
  actions: {
    async setPaymentChartFilter({ commit, dispatch, state }, payload) {
      const today = new Date();
      commit('setPaymentChartFilter', payload);
      await dispatch('getCallingChartData');
      let labels = null;
      if (payload === 'month') {
        labels = Array.from({ length: state.chartData.length }, (_, i) => new Intl.DateTimeFormat('en-GB').format(new Date(today.getFullYear(), today.getMonth(), i + 1)));
      } else {
        labels = Array.from({ length: 7 }, (_, i) => new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(new Date(today.getFullYear(), today.getMonth(), today.getDay() + i)));
      }
      commit('setPaymentLabels', labels);
    },
    async setCardChartFilter({ commit, dispatch }, payload) {
      commit('setCardChartFilter', payload);
      await dispatch('getBackendChartData');
    },
    async getCallingChartData({ commit, state, getters }) {
      const response = await fetch(`${baseUrl}/payment?filter=${state.paymentChartFilter}`, {
        headers: {
          Authorization: getters.getCredentialString,
        },
      });

      const { data: { chartData, summary } } = await response.json();

      let processedChartData = null;

      if (state.paymentChartFilter === 'week') {
        processedChartData = chartData.reduce((acc, next) => {
          const day = new Date(next.createdAt).getDay();
          acc[day] += next.amount / 100;
          return acc;
        }, Array.from(Array(7).fill(0)));
      } else {
        processedChartData = chartData.reduce((acc, next) => {
          const day = new Date(next.createdAt).getDate();
          acc[day - 1] += next.amount / 100;
          return acc;
        }, Array.from(Array(new Date().getDate()).fill(0)));
      }

      const total = processedChartData.reduce((acc, next) => {
        let localAccumulator = acc;
        localAccumulator += next;
        return localAccumulator;
      }, 0);
      commit('setChartData', processedChartData);
      commit('setCallingSummary', { ...summary, total });
    },
    async getBackendChartData({ commit, state, getters }) {
      const response = await fetch(`${baseUrl}/agent/summary?filter=${state.cardChartFilter}`, {
        headers: {
          Authorization: getters.getCredentialString,
        },
      });

      const { data: { chartData, summary } } = await response.json();
      const processedChartData = chartData.reduce((acc, next) => {
        const statusIndex = state.labels.findIndex((status) => status === next.status);
        if (statusIndex !== -1) {
          acc[statusIndex] += 1;
        }
        return acc;
      }, Array.from(Array(state.labels.length).fill(0)));
      commit('setCardChartData', processedChartData);
      commit('setBackendSummary', summary);
    },
  },
};

export default home;

<template>
  <nav class="level">
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Total Orders</p>
        <p class="title">{{ count }}</p>
      </div>
    </div>
    <div class="level-item">
      <b-field>
        <b-radio-button expanded native-value="ALL"
        v-model="timespan" @input="updateOrderViewTimespan"
            type="is-primary is-light">
              <span>All Time</span>
          </b-radio-button>

          <b-radio-button expanded native-value="MTD"
          v-model="timespan" @input="updateOrderViewTimespan"
          type="is-success is-light">
              <span>Month To Date</span>
          </b-radio-button>

          <b-radio-button expanded native-value="7"
          v-model="timespan" @input="updateOrderViewTimespan"
            type="is-danger is-light">
              <span>Last 7 days</span>
          </b-radio-button>

          <b-radio-button expanded native-value="30"
          v-model="timespan" @input="updateOrderViewTimespan"
            type="is-info is-light">
              <span>Last 30 days</span>
          </b-radio-button>
      </b-field>
    </div>
    <div class="level-item has-text-left">
      <b-field>
        <b-radio-button expanded :native-value="true" v-model="isSuccess" @input="updateSuccess"
          type="is-success">
            <b-icon icon="check"></b-icon>
            <span>Paid</span>
        </b-radio-button>
        <b-radio-button expanded :native-value="false" v-model="isSuccess" @input="updateSuccess"
            type="is-danger">
              <b-icon icon="close"></b-icon>
              <span>Unpaid</span>
          </b-radio-button>

      </b-field>
    </div>
    <div class="level-item has-text-left">
        <b-field>
          <b-radio-button expanded
           v-model="isAssigned" @input="updateIsUnassigned" :native-value="true">
            <b-icon icon="flag"></b-icon>
            Assigned
          </b-radio-button>

          <b-radio-button expanded
           @input="updateIsUnassigned" :native-value="false" v-model="isAssigned"
          type="is-danger">
            <b-icon icon-pack="far" icon="dumpster"></b-icon>
            No Processor
          </b-radio-button>
        </b-field>
      <!-- <b-field>
        <b-switch
        v-if="agent.type === 'Admin'"
        :value="isUnassigned" @input="updateIsUnassigned" passive-type="is-light"
          type="is-primary">
          {{ isUnassigned ? "Unassigned" : "All" }}
        </b-switch>
        </b-field>
        <b-field>
        <b-switch :value="success" @input="updateSuccess" passive-type="is-dark" type="is-warning">
          {{ success ? "Sale Only" : "All" }}
        </b-switch>
      </b-field> -->
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data: () => ({
    isSuccess: true,
    isAssigned: true,
    timespan: 'ALL',
  }),
  created() {
    this.isSuccess = this.success;
    this.isAssigned = !this.isUnassigned;
    this.timespan = this.orderTimespan;
  },
  computed: {
    ...mapState({
      orders: ({ ordersView: { orders } }) => orders,
      count: ({ ordersView: { count } }) => count,
      success: ({ ordersView: { success } }) => success,
      orderTimespan: ({ ordersView: { timespan } }) => timespan,
      isUnassigned: ({ ordersView: { isUnassigned } }) => isUnassigned,
      agent: ({ agentData: { agent } }) => agent,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
  },
  methods: {
    ...mapActions(['setSuccess', 'queryOrders', 'toggleIsUnassigned', 'setOrderViewTimespan']),
    async updateOrderViewTimespan(e) {
      this.setOrderViewTimespan(e);
      await this.queryOrders();
    },
    async updateIsUnassigned(e) {
      if (this.isAssigned !== !!e) {
        return null;
      }
      this.toggleIsUnassigned();
      await this.queryOrders();
      console.log('isUnassigned', this.isUnassigned);
      return null;
    },
    async updateSuccess(e) {
      this.setSuccess(e);
      await this.queryOrders();
    },
  },
};
</script>

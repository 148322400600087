import Vue from 'vue';
import Buefy from 'buefy';
import { ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import App from './App.vue';
import router from './router';
import store from './store';
import 'buefy/dist/buefy.css';
import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultContainerElement: '#content',
});

extend('namePart', {
  validate: (value) => value.length > 1 && value.length < 35,
  message: 'Please check this field.',
});

extend('NI', {
  validate: (value) => value.length > 5 && value.length < 15,
  message: 'Please check this field.',
});

extend('phone', {
  validate: (value) => value.length > 9 && value.length < 12,
  message: 'Please enter a valid phone number.',
});

extend('address', {
  validate: (value) => value.length > 0 && value.length < 80,
  message: 'Please check this field.',
});

extend('town', {
  validate: (value) => value.length > 1 && value.length < 40,
  message: 'Please check this field.',
});

extend('postcode', {
  validate: (value) => value.length > 1 && value.length < 40,
  message: 'Please enter a valid UK Postcode.',
});

extend('email', {
  ...email,
  message: 'Please enter a valid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

Vue.component('ValidationProvider', ValidationProvider);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');

import { baseUrl } from '@/common';

const cardDetail = {
  state: () => ({
    cardId: undefined,
    cardType: '',
    isRenewal: false,
    applicationType: undefined,
    trait: undefined,
    registrationNumber: undefined,
    testingId: undefined,
  }),
  getters: () => ({
    getCardType: ({ cardType }) => cardType,
  }),
  mutations: {
    setCardType(state, cardType) {
      state.cardType = cardType;
    },
    setRenewal(state, isRenewal) {
      state.isRenewal = isRenewal;
    },
    setTrait(state, trait) {
      state.trait = trait;
    },
    setCardId(state, cardId) {
      state.cardId = cardId;
    },
    setApplicationType(state, applicationType) {
      state.applicationType = applicationType;
    },
    setRegistrationNumber(state, registrationNumber) {
      state.registrationNumber = registrationNumber;
    },
    setTestingId(state, testingId) {
      state.testingId = testingId;
    },
  },
  actions: {
    setCardType({ commit }, payload) {
      commit('setCardType', payload);
    },
    setRenewal({ commit }, payload) {
      commit('setRenewal', payload);
    },
    setTrait({ commit }, payload) {
      commit('setTrait', payload);
    },
    setCardId({ commit }, payload) {
      commit('setCardId', payload);
    },
    setApplicationType({ commit }, payload) {
      commit('setApplicationType', payload);
    },
    setRegistrationNumber({ commit }, payload) {
      commit('setRegistrationNumber', payload);
    },
    setTestingId({ commit }, payload) {
      commit('setTestingId', payload);
    },
    async addCard({
      commit, state, getters, dispatch,
    }) {
      const response = await fetch(`${baseUrl}/card`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          userId: getters.getUserId,
          orderId: getters.getOrderId,
          type: state.cardType,
          status: state.status,
          applicationType: state.applicationType,
          registrationNumber: state.registrationNumber,
          testingId: state.testingId,
          trait: state.trait,
        }),
      });

      const { data: { orderId } } = await response.json();
      commit('setOrderId', orderId);
      return dispatch('getOrder');
    },
    async updateCardDetails({ commit, getters, state }, payload) {
      const response = await fetch(`${baseUrl}/card/${state.cardId}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify(payload),
      });

      const { data: { order } } = await response.json();

      commit('setOrder', order);
    },
  },
};

export default cardDetail;

/* eslint-disable no-mixed-operators */
/* eslint-disable no-plusplus */

export const baseHost = process.env.VUE_APP_BASE_SERVER_URL;
export const wsBaseUrl = process.env.VUE_APP_BASE_WS_URL;

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const publicServerKey = urlBase64ToUint8Array(
  'BM9G2ctvdl00PzD3HHPC5bdOR6eePuooF9eVp-FB1a-pNM5Gdv2dafywWPQf5LlcuEcPiI2KW3CDtoXPA2Ux8iU',
);

import { baseUrl, getSessionObject } from '@/common';
import { pascalCase } from 'change-case';

const primary = {
  state: () => ({
    credentialString: getSessionObject('credentialString'),
    connection: null,
    leads: [],
    userId: null,
    allUsers: [],
    user: {},
    page: 1,
    filter: 'status',
    filterStatus: 'Open',
    sortByCreatedAt: false,
    isLoading: false,
  }),
  mutations: {
    setLeads(state, leads) {
      state.leads = leads;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUser(state, user) {
      state.user = user;
    },
    setConnection(state, connection) {
      state.connection = connection;
    },
    setAllUsers(state, allUsers) {
      state.allUsers = allUsers;
    },
    updateUserAgent(state, diff) {
      const { leads: allLeads } = state;
      console.log(diff, allLeads);
      const userIndex = allLeads.findIndex((user) => user.id === diff.userId);
      console.log(userIndex);
      allLeads[userIndex].agentId = diff.agentId;
      state.leads = allLeads;
    },
    setLeadDuplicate(state) {
      const allLeads = state.leads;
      const userIndex = state.leads.findIndex(({ id }) => id === state.userId);
      const user = state.leads[userIndex];
      user.status = 'Duplicate';
      allLeads[userIndex] = user;
      state.leads = allLeads;
    },
    changeAgent(state, agentId) {
      state.user.agentId = agentId;
    },
    removeOrderFromUser(state, orderId) {
      const { user: localUser } = state;
      const orderIndex = localUser.Orders.findIndex((order) => order.id === orderId);
      localUser.Orders.splice(orderIndex, 1);
      state.user = localUser;
    },
    removeAgent(state) {
      state.user.Agent = undefined;
    },
    setUserComment(state, comment) {
      const Comments = [...state.user.Comments, comment];
      state.user = { ...state.user, Comments };
    },
    setPage(state, page) {
      state.page = page;
    },
    setFilterStatus(state, filterStatus) {
      state.filterStatus = filterStatus;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setCredentialString(state, credentialString) {
      sessionStorage.credentialString = JSON.stringify(credentialString);
      state.credentialString = credentialString;
    },
    setSortByCreatedAt(state, sortByCreatedAt) {
      state.sortByCreatedAt = sortByCreatedAt;
    },
    setIsLoading(state, loadingStatus) {
      state.isLoading = loadingStatus;
    },
  },
  getters: {
    getCredentialString: ({ credentialString }) => credentialString,
    getFilter: ({ filter }) => filter,
  },
  actions: {
    setUserId({ commit }, payload) {
      commit('setUserId', payload);
    },
    setFilter({ commit }, payload) {
      commit('setFilter', payload);
    },
    setFilterStatus({ commit }, payload) {
      commit('setFilterStatus', payload);
    },
    setCredentialString({ commit }, payload) {
      sessionStorage.credentialString = payload;
      commit('setCredentialString', payload);
    },
    setConnection({ commit }, payload) {
      commit('setConnection', payload);
    },
    setSortByCreatedAt({ commit }, payload) {
      commit('setSortByCreatedAt', payload);
    },
    setIsLoading({ commit }, payload) {
      commit('setIsLoading', payload);
    },
    updateUserAgent({ commit }, payload) {
      commit('updateUserAgent', payload);
    },
    updatePage({ commit, state }, payload) {
      if (payload === 'INCREMENT') {
        commit('setPage', state.page + 1);
      } else if (payload === 'DECREMENT') {
        if (state.page > 1) {
          commit('setPage', state.page - 1);
        } else {
          commit('setPage', 1);
        }
      } else if (typeof payload === 'number') {
        commit('setPage', payload);
      }
    },
    setLeadDuplicate({ commit }) {
      commit('setLeadDuplicate');
    },
    async addAgentToLead({
      state, getters, commit,
    },
    payload) {
      const response = await fetch(`${baseUrl}/agent/${state.userId}?agent_id=${payload}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
      });

      if (response.status === 400) {
        throw new Error('Incorrect data');
      }

      if (response.status === 422) {
        throw new Error('Already assigned');
      }

      if (response.status === 428) {
        throw new Error('Not a valid agent');
      }

      if (response.status === 204) {
        commit('changeAgent', payload);
      }
    },
    async removeAgentToLead({ state, commit, getters }, payload) {
      const response = await fetch(`${baseUrl}/agent/${state.userId}?agent_id=${payload}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          Authorization: getters.getCredentialString,
        },
      });

      if (response.status === 400) {
        throw new Error('Incorrect data');
      }

      if (response.status === 422) {
        throw new Error('Already assigned');
      }

      if (response.status === 428) {
        throw new Error('Not a valid agent');
      }
      if (response.status === 204) {
        commit('removeAgent');
      }
    },
    async validateCredentials({ commit }, payload) {
      const loginCredentials = `Basic ${btoa(`${payload.username}:${payload.password}`)}`;
      const response = await fetch(`${baseUrl}/login`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: loginCredentials,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        throw new Error('Invalid credentials');
      }

      if (response.status === 200) {
        const {
          data: {
            agent, allAgents, locations, login,
          },
        } = await response.json();
        commit('setCredentialString', loginCredentials);
        commit('setAgent', agent);
        sessionStorage.loginId = JSON.stringify(login.id);
        sessionStorage.loginToken = JSON.stringify(login.loginToken);
        sessionStorage.loginCreatedAt = JSON.stringify(login.createdAt);
        commit('setAllAgents', allAgents);
        commit('setAllLocations', locations);
        return null;
      }

      throw new Error('Login failed');
    },
    async getLeads({ commit, state, getters }) {
      let response;

      if (state.filter === 'status' && state.filterStatus) {
        response = await fetch(`${baseUrl}/candidate?page=${state.page}&filter=status&status=${state.filterStatus}&sort=${state.sortByCreatedAt ? 'createdAt' : 'updatedAt'}`, {
          headers: {
            Authorization: getters.getCredentialString,
          },
        });
      } else if (state.filter && state.filter !== 'status') {
        response = await fetch(`${baseUrl}/candidate?page=${state.page}&filter=${state.filter}&sort=${state.sortByCreatedAt ? 'createdAt' : 'updatedAt'}`, {
          headers: {
            Authorization: getters.getCredentialString,
          },
        });
      } else {
        response = await fetch(`${baseUrl}/candidate?page=${state.page}&sort=${state.sortByCreatedAt ? 'createdAt' : 'updatedAt'}`, {
          headers: {
            Authorization: getters.getCredentialString,
          },
        });
      }

      const { data: { users } } = await response.json();

      commit('setLeads', users);
    },
    async getLeadDetails({ commit, getters, state }) {
      const response = await fetch(`${baseUrl}/candidate/${state.userId}`, {
        headers: {
          Authorization: getters.getCredentialString,
        },
      });
      const { data: { user, allUsers } } = await response.json();

      commit('setUser', user);
      commit('setAllUsers', allUsers);
    },
    async updateCandidateDetails({ commit, getters, state }, payload) {
      const response = await fetch(`${baseUrl}/candidate/${state.userId}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 422) {
        throw new Error('Cannot be marked as Duplicate');
      }
      if (response.status === 400) {
        throw new Error('Invalid input for field change');
      }
      const { data: { user } } = await response.json();

      commit('setUser', user);
    },
    async publishComment({
      commit, state, getters, rootState,
    }, payload) {
      const response = await fetch(`${baseUrl}/comment`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          userId: state.userId,
          text: payload,
        }),
      });
      const { data: { comment } } = await response.json();

      const agent = rootState.agentData.allAgents.find(({ id }) => id === comment.agentId);
      comment.Agent = agent;

      commit('setUserComment', comment);
    },
    async publishGenComment({ commit, getters, rootState }, payload) {
      const response = await fetch(`${baseUrl}/comment/gen`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify(payload),
      });
      const { data: { comment } } = await response.json();
      const agent = rootState.agentData.allAgents.find(({ id }) => id === comment.agentId);
      comment.Agent = agent;

      commit(`set${pascalCase(payload.commentableType)}Comment`, comment);
    },
    async sendCustomEmail({ getters, state }, {
      replyTo, from, subject, body,
    }) {
      const response = await fetch(`${baseUrl}/candidate/mail/${state.userId}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          from,
          subject,
          body: `\n${body}`,
          replyTo,
        }),
      });

      if (response.status === 204) {
        return null;
      }
      throw new Error('Error while sending email');
    },
  },
};

export default primary;

import { baseUrl, getSessionObject, getAgentBreakTimes } from '@/common';

const agentData = {
  state: () => ({
    agent: getSessionObject('agent'),
    allAgents: getSessionObject('allAgents'),
    loginMonth: undefined,
    logins: [],
    processedData: [],
  }),
  mutations: {
    setAgent(state, agent) {
      sessionStorage.agent = JSON.stringify(agent);
      state.agent = agent;
    },
    setAllAgents(state, allAgents) {
      sessionStorage.allAgents = JSON.stringify(allAgents);
      state.allAgents = allAgents;
    },
    setAgentLogins(state, logins) {
      state.logins = logins;
    },
    setAgentLoginMonth(state, loginMonth) {
      state.loginMonth = loginMonth;
    },
    setProcessedData(state, processedData) {
      state.processedData = processedData;
    },
    updateAgentLoginStatus(state, diff) {
      const { allAgents: _allAgents } = state;
      const userIndex = _allAgents.findIndex((agent) => agent.id === diff.id);
      _allAgents[userIndex].status = diff.status;
      state.allAgents = _allAgents;
    },
  },
  actions: {
    setAgentLoginMonth({ commit }, payload) {
      commit('setAgentLoginMonth', payload);
    },
    updateAgentLoginStatus({ commit }, payload) {
      commit('updateAgentLoginStatus', payload);
    },
    async setAgent({ commit, getters }) {
      const response = await fetch(`${baseUrl}/agent`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: getters.getCredentialString,
        },
      });

      const { data: { agent } } = await response.json();

      commit('setAgent', agent);
    },
    async setAllAgents({ commit, getters }) {
      const response = await fetch(`${baseUrl}/agent/status`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: getters.getCredentialString,
        },
      });

      const { data: { allAgents } } = await response.json();

      commit('setAllAgents', allAgents);
    },
    async setAgentLogins({ commit, getters, state }, payload) {
      const response = await fetch(`${baseUrl}/agent/break?agent_id=${payload}${state.loginMonth ? `&month=${state.loginMonth}` : ''}`, {
        headers: {
          Authorization: getters.getCredentialString,
        },
      });

      const { data: { agentBreaks } } = await response.json();

      commit('setAgentLogins', agentBreaks);
      let processedChartData = null;

      if (agentBreaks.length) {
        const today = new Date(agentBreaks[0].createdAt);
        processedChartData = agentBreaks.reduce((acc, next) => {
          const day = new Date(next.createdAt).getDate();
          acc[day - 1] += getAgentBreakTimes(next);
          return acc;
        }, Array.from(Array(new Date(
          today.getFullYear(), today.getMonth() + 1, 0,
        ).getDate()).fill(0)));
      } else {
        processedChartData = [];
      }

      commit('setProcessedData', processedChartData);
    },
  },
};

export default agentData;

/* eslint-disable no-useless-catch */
import { baseHost } from './constants';

const { CognitoIdentityClient } = require('@aws-sdk/client-cognito-identity');
const {
  fromCognitoIdentityPool,
} = require('@aws-sdk/credential-provider-cognito-identity');
const {
  S3Client, PutObjectCommand, ListObjectsCommand, HeadObjectCommand, DeleteObjectCommand,
} = require('@aws-sdk/client-s3');
// Set the AWS Region
// const REGION = 'us-east-2'; // REGION
export const REGION = 'ap-south-1'; // REGION

const albumBucketName = 'csc-card';
let s3 = null;

const createAlbum = async (albumName) => {
  // Initialize the Amazon Cognito credentials provider
  const response = await fetch(`${baseHost}/key?u=s3`, {
    headers: {
      Authorization: sessionStorage.credentialString,
    },
  });

  const { data: { key } } = await response.json();

  s3 = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: REGION }),
      identityPoolId: `${REGION}:${key}`, // IDENTITY_POOL_ID
    }),
  });

  const newLocal = albumName.trim();
  if (!newLocal) {
    throw new Error('Album names must contain at least one non-space character.');
  }
  if (newLocal.indexOf('/') !== -1) {
    throw new Error('Album names cannot contain slashes.');
  }
  const albumKey = encodeURIComponent(newLocal);
  try {
    await s3.send(new HeadObjectCommand({
      Key: albumKey,
      Bucket: albumBucketName,
    }));
    return albumKey;
  } catch (error) {
    await s3.send(new PutObjectCommand(
      {
        Key: albumKey,
        Bucket: albumBucketName,
      },
    ));
    return albumKey;
  }
};

export const addPhoto = async function addPhoto(id, files) {
  let albumName = null;
  albumName = await createAlbum(id);

  console.log(albumName, 'Album Name');
  console.log(typeof albumName, 'Album Type');

  const uploadToCloud = async (file) => {
    const fileName = file.name;
    const photoKey = `${albumName}/${fileName}`;
    const uploadParams = {
      Bucket: albumBucketName,
      Key: photoKey,
      Body: file,
    };
    await s3.send(new PutObjectCommand(uploadParams));
    console.log('Successfully uploaded photo.', fileName);
    return fileName;
  };

  try {
    await s3.send(
      new ListObjectsCommand({
        Prefix: `${albumName}/`,
        Bucket: albumBucketName,
      }),
    );

    return Promise.all(files.map(uploadToCloud));
  } catch (err) {
    throw new Error('Error while uploading document: ', err.message);
  }
};

export const deletePhoto = async function deletePhoto(id, files) {
  const uploadToCloud = async (file) => {
    const fileName = file.name;
    const photoKey = `${id}/${fileName}`;
    const params = {
      Bucket: albumBucketName,
      Key: photoKey,
    };
    await s3.send(new DeleteObjectCommand(params));
    console.log('Successfully deleted photo.', fileName);
    return fileName;
  };

  try {
    return Promise.all(files.map(uploadToCloud));
  } catch (err) {
    throw new Error('Error while deleting document: ', err.message);
  }
};

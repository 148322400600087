import { baseUrl } from '@/common';

const courseDetails = {
  state: () => ({
    courseId: undefined,
    type: undefined,
    finalAssessmentDate: undefined,
    status: undefined,
    courseTrait: undefined,
    comment: undefined,
  }),
  getters: () => ({
    getCourseTrait: ({ courseTrait }) => courseTrait,
    getCourseType: ({ type }) => type,
  }),
  mutations: {
    setCourseTrait(state, courseTrait) {
      state.courseTrait = courseTrait;
    },
    setCourseId(state, courseId) {
      state.courseId = courseId;
    },
    setCourseType(state, type) {
      state.type = type;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setFinalAssessmentDate(state, finalAssessmentDate) {
      state.finalAssessmentDate = finalAssessmentDate;
    },
    setCourseComment(state, comment) {
      state.comment = comment;
    },
  },
  actions: {
    setCourseTrait({ commit }, payload) {
      commit('setCourseTrait', payload);
    },
    setCourseId({ commit }, payload) {
      commit('setCourseId', payload);
    },
    setCourseType({ commit }, payload) {
      commit('setCourseType', payload);
    },
    setStatus({ commit }, payload) {
      commit('setStatus', payload);
    },
    setFinalAssessmentDate({ commit }, payload) {
      commit('setFinalAssessmentDate', payload);
    },
    setCourseComment({ commit }, payload) {
      commit('setCourseComment', payload);
    },
    async addCourse({
      commit, state, getters, dispatch,
    }) {
      const response = await fetch(`${baseUrl}/course/online`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          userId: getters.getUserId,
          orderId: getters.getOrderId,
          trait: state.courseTrait || undefined,
          date: state.finalAssessmentDate,
          type: state.type,
        }),
      });

      const { data: { orderId } } = await response.json();

      commit('setOrderId', orderId);
      return dispatch('getOrder');
    },
    async updateCourseDetails({
      commit, getters, state,
    }, payload) {
      const response = await fetch(`${baseUrl}/course/online/${state.courseId}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify(payload),
      });

      const { data: { order } } = await response.json();

      commit('setOrder', order);
    },
  },
};

export default courseDetails;

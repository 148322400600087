<template>
  <div class="sidebar-page">
    <section class="sidebar-layout">
      <b-sidebar
        position="static"
        :mobile="mobile"
        :expand-on-hover="expandOnHover"
        :reduce="reduce"
        type="is-light"
        class="has-text-info"
        open
      >
        <div class="p-1">
          <b-menu class="is-custom-mobile" id="sidebar-menu">
            <b-menu-list :label="currentTime"> </b-menu-list>
            <b-menu-list label="Menu">
              <b-menu-item tag="router-link" to="/" icon="home" label="Home">
              </b-menu-item>
              <b-menu-item
                tag="router-link"
                to="/leads"
                icon="user-tag"
                label="Leads"
              >
              </b-menu-item>
              <b-menu-item
                to="/tickets"
                tag="router-link"
                label="Ticket"
                icon="tag"
              >
              </b-menu-item>
              <b-menu-list expanded label="View">
                <b-menu-item
                  to="/orders"
                  tag="router-link"
                  icon="shopping-cart"
                  label="Orders"
                >
                </b-menu-item>
                <b-menu-item
                  to="/charges"
                  tag="router-link"
                  icon="pound-sign"
                  label="Charges"
                >
                </b-menu-item>
              </b-menu-list>
              <b-menu-list expanded label="Products">
                <b-menu-item
                  to="/tests"
                  tag="router-link"
                  icon="school"
                  label="CITB Test"
                >
                </b-menu-item>
                <b-menu-item
                  to="/cards"
                  tag="router-link"
                  icon="id-card"
                  label="Card"
                >
                </b-menu-item>
                <b-menu-item
                  to="/course/online"
                  tag="router-link"
                  icon="award"
                  label="Courses"
                >
                </b-menu-item>
                <b-menu-item
                  to="/course/offline"
                  tag="router-link"
                  icon="location-arrow"
                  label="One Day Courses"
                >
                </b-menu-item>
                <b-menu-item
                  to="/products"
                  tag="router-link"
                  icon="bag-shopping"
                  label="Products"
                >
                </b-menu-item>
              </b-menu-list>
              <b-menu-item
                v-if="agent.type === 'Admin'"
                to="/agents/status"
                tag="router-link"
                icon="teamspeak"
                icon-pack="fab"
                label="Team"
              >
              </b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
      </b-sidebar>
      <div class="container ml-2 mr-2 mb-5">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      expandOnHover: true,
      mobile: 'reduce',
      reduce: true,
    };
  },
  computed: {
    ...mapState({
      credentialString: (state) => state.primary.credentialString,
      agent: ({ agentData: { agent } }) => agent,
    }),
    currentTime() {
      return new Intl.DateTimeFormat('en-GB', {
        timeStyle: 'short',
        timeZone: 'Europe/London',
        hour12: true,
      })
        .format(new Date())
        .split('BST')[0];
    },
  },
  methods: {
    ...mapActions(['setCredentialString']),
    removeCredentail() {
      sessionStorage.credentialString = undefined;
      sessionStorage.agent = undefined;
      this.setCredentialString(undefined);
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style lang="scss">
.p-1 {
  padding: 1em;
}
.sidebar-page {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }
}
@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        .menu-label {
          font-weight: bold !important;
        }
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        .menu-label:first-of-type {
          font-weight: bold;
        }
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.is-mini-expand {
  .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

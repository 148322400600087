/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { publicKey, baseUrl } from './common';

function askPermission() {
  return new Promise(((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }))
    .then((permissionResult) => {
      if (permissionResult !== 'granted') {
        throw new Error('We weren\'t granted permission.');
      }
    });
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(reg) {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );

      reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicKey,
      }).then((sub) => {
        console.log(sub);
        console.log('Endpoint URL: ', sub.endpoint);
        return fetch(`${baseUrl}/subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(sub),
        });
      }).catch((e) => {
        if (Notification.permission === 'denied') {
          console.warn('Permission for notifications was denied');
        } else {
          console.error('Unable to subscribe to push', e);
        }
      });
    },
    registered(reg) {
      console.log('Service worker has been registered.');

      reg.pushManager.getSubscription().then((sub) => {
        if (sub === null) {
          // Update UI to ask user to register for Push
          askPermission();
          console.log('Not subscribed to push service!');
        } else {
          // We have a subscription, update the database
          console.log('Subscription object: ', sub);
        }
      });
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}

import { baseUrl } from '@/common';

const personalDetails = {
  state: () => ({
    title: 'Mr',
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
    DOB: undefined,
    NI: undefined,
    cardRegistrationNumber: undefined,
    phoneNumber: undefined,
    email: undefined,
    alternateEmail: undefined,
    address: undefined,
    town: undefined,
    postCode: undefined,
    trade: undefined,
    userId: undefined,
    comment: undefined,
    status: undefined,
  }),
  getters: {
    getName: ({
      title, firstName, middleName, lastName,
    }) => `${title}. ${firstName} ${middleName || ''} ${lastName}`,
    getTitle: ({ title }) => title,
    getFirstName: ({ firstName }) => firstName,
    getLastName: ({ lastName }) => lastName,
    getDOB: ({ DOB }) => DOB,
    getNI: ({ NI }) => NI,
    getPhoneNumber: ({ phoneNumber }) => phoneNumber,
    getEmail: ({ email }) => email,
    getAddress: ({ address }) => address,
    getPostcode: ({ postCode }) => postCode,
    getFullAddress: ({ address, town, postCode }) => `${address} ${town} - ${postCode}`,
    getUserId: ({ userId }) => userId,
  },
  mutations: {
    setTitle(state, title) {
      state.title = title;
    },
    setFirstName(state, firstName) {
      state.firstName = firstName;
    },
    setMiddleName(state, middleName) {
      state.middleName = middleName;
    },
    setLastName(state, lastName) {
      state.lastName = lastName;
    },
    setDOB(state, DOB) {
      state.DOB = DOB;
    },
    setNI(state, NI) {
      state.NI = NI;
    },
    setPhoneNumber(state, phoneNumber) {
      state.phoneNumber = phoneNumber;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setAlternateEmail(state, alternateEmail) {
      state.alternateEmail = alternateEmail;
    },
    setAddress(state, address) {
      state.address = address;
    },
    setTown(state, town) {
      state.town = town;
    },
    setPostCode(state, postCode) {
      state.postCode = postCode;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setCardRegistrationNumber(state, cardRegistrationNumber) {
      state.cardRegistrationNumber = cardRegistrationNumber;
    },
    setComment(state, comment) {
      state.comment = comment;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setTrade(state, trade) {
      state.trade = trade;
    },
  },
  actions: {
    setTitle({ commit }, payload) {
      commit('setTitle', payload);
    },
    setFirstName({ commit }, payload) {
      commit('setFirstName', payload);
    },
    setMiddleName({ commit }, payload) {
      commit('setMiddleName', payload);
    },
    setLastName({ commit }, payload) {
      commit('setLastName', payload);
    },
    setDOB({ commit }, payload) {
      commit('setDOB', payload);
    },
    setNI({ commit }, payload) {
      commit('setNI', payload);
    },
    setCardRegistrationNumber({ commit }, payload) {
      commit('setCardRegistrationNumber', payload);
    },
    setPhoneNumber({ commit }, payload) {
      commit('setPhoneNumber', payload);
    },
    setEmail({ commit }, payload) {
      commit('setEmail', payload);
    },
    setAlternateEmail({ commit }, payload) {
      commit('setAlternateEmail', payload);
    },
    setAddress({ commit }, payload) {
      commit('setAddress', payload);
    },
    setTown({ commit }, payload) {
      commit('setTown', payload);
    },
    setPostCode({ commit }, payload) {
      commit('setPostCode', payload);
    },
    setTrade({ commit }, payload) {
      commit('setTrade', payload);
    },
    setComment({ commit }, payload) {
      commit('setComment', payload);
    },
    setStatus({ commit }, payload) {
      commit('setStatus', payload);
    },
    async addCandidate({ state, commit, getters }) {
      const responseSt = await fetch(`${baseUrl}/candidate`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          title: state.title,
          firstName: state.firstName,
          middleName: state.middleName || undefined,
          lastName: state.lastName,
          dateOfBirth: state.DOB || undefined,
          nationalInsuranceNumber: state.NI || undefined,
          email: state.email || undefined,
          alternateEmail: state.alternateEmail || undefined,
          address: state.address || undefined,
          town: state.town || undefined,
          postcode: state.postCode || undefined,
          trade: state.trade || undefined,
          phoneNumber: state.phoneNumber || undefined,
          cardRegistrationNumber: state.cardRegistrationNumber || undefined,
          comment: state.comment || undefined,
          status: state.status,
        }),
      });

      const response = await responseSt.json();

      if (response.statusCode === 400) {
        throw new Error('Validation failed');
      }

      commit('setUserId', response.data.userId);

      return response.data.userId;
    },
  },
};

export default personalDetails;

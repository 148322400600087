import Vue from 'vue';
import Vuex from 'vuex';
import primary from './primary';
import home from './home';
import cardsView from './cards-view';
import testsView from './tests-view';
import onlineCoursesView from './online-courses-view';
import ordersView from './orders-view';
import chargesView from './charges-view';
import personalDetails from './personal-detail';
import testDetails from './test-detail';
import cardDetail from './card-detail';
import courseDetails from './course-detail';
import orderDetail from './order-detail';
import ticketsView from './tickets-view';
import agentData from './agent-data';
import invoiceDetail from './invoice-detail';
import oneDayCourseDetails from './one-day-course-detail';
import oneDayCourseView from './one-day-course-view';
import productDetails from './product-detail';
import productsView from './product-view';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    primary,
    home,
    cardsView,
    testsView,
    onlineCoursesView,
    personalDetails,
    testDetails,
    cardDetail,
    courseDetails,
    orderDetail,
    ordersView,
    chargesView,
    ticketsView,
    agentData,
    invoiceDetail,
    oneDayCourseDetails,
    oneDayCourseView,
    productDetails,
    productsView,
  },
});

import { baseHost, wsBaseUrl, publicServerKey } from './constants';
import { addPhoto, deletePhoto } from './s3';

export const validate = function validate(inputs) {
  return Object.keys(inputs).filter((input) => !inputs[input]);
};

export const getClass = function getClass(status) {
  let result;
  switch (status) {
    case 'Self':
      result = 'has-background-primary has-text-white';
      break;
    case 'Sale':
      result = 'has-background-success has-text-white-ter';
      break;
    case 'Open':
      result = 'has-background-warning-light';
      break;
    case 'Callback':
      result = 'has-background-danger-light';
      break;
    case 'Duplicate':
      result = 'has-background-grey-light';
      break;
    case 'Answering Machine':
      result = 'has-background-info has-text-white-bis';
      break;
    case 'Dispute':
      result = 'has-background-warning has-text-white-bis';
      break;
    case 'Refund':
      result = 'has-background-danger has-text-white-bis';
      break;
    case 'Pending Payment':
      result = 'has-background-link-dark has-text-white-bis';
      break;
    default:
      result = 'has-background-grey-light has-text-white-bis';
      break;
  }
  return result;
};

export const getAgentById = function getAgentNameById(agentId) {
  try {
    return this.allAgents.find(({ id }) => id === agentId).name;
  } catch (error) {
    throw new Error('Error while fetching the agent from agent pool data. Make sure you have agentDetails: { allAgents } in computed property for the component.', error);
  }
};

export const getLocationById = function getLocationById(locationId) {
  try {
    return this.locations.find(({ id }) => id === locationId).name;
  } catch (error) {
    throw new Error('Error while fetching the agent from agent pool data. Make sure you have agentDetails: { allAgents } in computed property for the component.', error);
  }
};

export const getAgentBreakTimes = function getAgentBreakTimes(_break) {
  if (!_break.completedAt) return 0;
  const elapsedMilliSeconds = new Date(_break.completedAt).getTime()
    - new Date(_break.createdAt).getTime();

  return Number(elapsedMilliSeconds / 1000);
  // const elapsedMinutes = elapsedNetSeconds / 60;
  // const elapsedSeconds = elapsedNetSeconds % 60;
  // console.log('elapsed minutes', elapsedMinutes);

  // return [elapsedMinutes.toFixed(0), elapsedSeconds.toFixed(0)];
};

export const getTypeByStatus = function getTypeByStatus(status) {
  if (status === 'Offline') {
    return 'is-danger';
  } if (status === 'Online') {
    return 'is-success';
  }
  return 'is-warning';
};

export const navigateToCandidate = function navigateToCandidate(userId, n) {
  this.setUserId(userId);
  this.$router.push(
    {
      path: `/candidate/${userId}`,
      query: { n },
    },
  );
};

export const navigateToOrder = function navigateToOrder(order) {
  this.$router.push(
    {
      name: 'Order',
      query: {
        o: typeof order === 'string' ? order : order.id,
      },
    },
  );
};

export const createSnackbar = function createSnackbar(message, success, container) {
  return this.$buefy.toast.open({
    message,
    type: success ? 'is-success' : 'is-danger',
    duration: 3500,
    container,
    position: 'is-top',
  });
};

const convertToUKTimezone = function convertToUKTimezone(dateTime, options = {}) {
  return new Intl.DateTimeFormat('en-GB', {
    ...options, timeZone: 'Europe/London',
  }).format(new Date(dateTime)).split('BST')[0];
};

export const getHumanDate = function getHumanReadableDate(dateTime, showTime = false) {
  const today = new Date();
  const midnightMoment = new Date(today.getFullYear(), today.getMonth(), convertToUKTimezone(today, { day: 'numeric' }), 4, 30);
  const millisecondsSinceMidnight = midnightMoment - new Date(dateTime);

  const timeString = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium', timeStyle: 'long', timeZone: 'Europe/London',
  }).format(new Date(dateTime)).split('BST')[0];

  if (millisecondsSinceMidnight <= 0) {
    return showTime ? `Today at ${timeString.split(', ')[1]}` : 'Today';
  }

  if (millisecondsSinceMidnight < 86400000) {
    return showTime ? `Yesterday at ${timeString.split(', ')[1]}` : 'Yesterday';
  } // 24 * 60 * 60 * 1000
  //   const yearDiff = today.getFullYear() - new Date(dateTime).getFullYear();
  //   const monthDiff = Math.abs(today.getMonth() - new Date(dateTime).getMonth());
  //   const dateDiff = new Date(dateTime).getDate() - today.getDate();
  //   const dayDiff = Math.abs(today.getDay() - new Date(dateTime).getDay());

  //   if (yearDiff === 0) {
  //     if (monthDiff === 0) {
  //       if (dateDiff === 0) {
  //         return 'Today';
  //       } if (dateDiff === -1) {
  //         return 'Yesterday';
  //       }
  //     }
  //     if (monthDiff === 1) {
  //       if (dateDiff > 26) {
  //         if (dayDiff === 0) {
  //           return 'Today';
  //         } if (dayDiff === 1) {
  //           return 'Yesterday';
  //         }
  //       }
  //     }
  //   }

  return showTime ? timeString : timeString.split(' at')[0];
};

export const getSessionObject = function getSessionObject(obj) {
  try {
    return JSON.parse(sessionStorage[obj]);
  } catch (error) {
    return undefined;
  }
};

export const getClassByCardType = function getClassByCardType(cardType) {
  switch (cardType) {
    case 'Green Labourer Card': {
      return 'has-background-success has-text-white-ter';
    }
    case 'Gold Advanced Craft Card': {
      return 'has-background-warning';
    }
    case 'Gold Supervisor Card': {
      return 'has-background-warning-dark has-text-white-ter';
    }
    case 'Red Apprentice Card': {
      return 'has-background-danger has-text-white-ter';
    }
    case 'Red Provisional Card': {
      return 'has-background-danger-light';
    }
    case 'Red Technical Supervisor/Manager Card': {
      return 'has-background-danger-light';
    }
    case 'Red Trainee Card': {
      return 'has-background-danger has-text-white-ter';
    }
    case 'Red Experienced Worker Card': {
      return 'has-background-danger has-text-white-ter';
    }
    case 'ECS Card': {
      return 'has-background-primary-dark has-text-white-ter';
    }
    case 'Blue Skilled Worker Card': {
      return 'has-background-info-dark has-text-white-ter';
    }
    case 'Black Manager Card': {
      return 'has-background-grey-darker has-text-white-ter';
    }
    case 'White Professionally Qualified Person': {
      return 'has-background-grey-lighter';
    }
    case 'White Academically Qualified Person': {
      return 'has-background-grey-lighter';
    }
    case 'SkillCard': {
      return 'has-background-success-dark has-text-white-ter';
    }
    case 'JIB Card': {
      return 'has-background-info-light';
    }
    default: {
      return '';
    }
  }
};

export const generatedInvoiceDownloadLink = function generatedInvoiceDownloadLink(chargeId) {
  return `${baseHost}/charge/invoice/${chargeId}`;
};

export const baseUrl = baseHost;
export {
  wsBaseUrl,
};
export const publicKey = publicServerKey;
export const getClassByStatus = getClass;
export const uploadPhoto = addPhoto;
export const removePhoto = deletePhoto;
export const s3Url = 'https://csc-card.s3.ap-south-1.amazonaws.com';

/* eslint-disable no-await-in-loop */
import { baseUrl } from '@/common';

const invoiceDetail = {
  state: () => ({
    invoiceId: '',
    invoiceeName: '',
    invoiceItems: [],
    invoiceItemName: '',
    invoiceItemQuantity: 1,
    invoiceItemPrice: 0.00,
    invoiceItemTax: 0.00,
  }),
  mutations: {
    setInvoiceId(state, invoiceId) {
      state.invoiceId = invoiceId;
    },
    setInvoiceeName(state, invoiceeName) {
      state.invoiceeName = invoiceeName;
    },
    resetInvoiceItems(state) {
      state.invoiceItems = [];
    },
    appendInvoiceItems(state, invoiceItem) {
      if (state.invoiceItems.length > 0) {
        const existingItem = state.invoiceItems.find((item) => item.id === invoiceItem.id);
        if (!existingItem) {
          state.invoiceItems = [...state.invoiceItems, invoiceItem];
        }
      } else {
        state.invoiceItems = [invoiceItem];
      }
    },
    replaceInvoiceItems(state, { previousItemId, payload }) {
      const existingItemIndex = state.invoiceItems.findIndex((item) => item.id === previousItemId);
      const localState = state.invoiceItems;
      localState[existingItemIndex] = payload;
      state.invoiceItems = localState;
    },
    setInvoiceItemName(state, invoiceItemName) {
      state.invoiceItemName = invoiceItemName;
    },
    setInvoiceItemQuantity(state, invoiceItemQuantity) {
      state.invoiceItemQuantity = invoiceItemQuantity;
    },
    setInvoiceItemPrice(state, invoiceItemPrice) {
      state.invoiceItemPrice = invoiceItemPrice;
    },
    setInvoiceItemTax(state, invoiceItemTax) {
      state.invoiceItemTax = invoiceItemTax;
    },
  },
  actions: {
    setInvoiceeName({ commit }, payload) {
      commit('setInvoiceeName', payload);
    },
    setInvoiceItemName({ commit }, payload) {
      commit('setInvoiceItemName', payload);
    },
    setInvoiceItemQuantity({ commit }, payload) {
      commit('setInvoiceItemQuantity', payload);
    },
    setInvoiceItemPrice({ commit }, payload) {
      commit('setInvoiceItemPrice', payload);
    },
    setInvoiceItemTax({ commit }, payload) {
      commit('setInvoiceItemTax', payload);
    },
    setInvoiceItems({ commit }, payload) {
      if (payload) {
        commit('appendInvoiceItems', payload);
      } else {
        commit('resetInvoiceItems');
      }
    },
    async findOrCreateInvoiceItem({ commit, getters, state }, payload) {
      let body = null;
      let parsedBody = null;

      if (payload) {
        body = JSON.stringify(payload);
      } else {
        parsedBody = {
          name: state.invoiceItemName,
          price: state.invoiceItemPrice,
          quantity: state.invoiceItemQuantity,
          tax: state.invoiceItemTax,
        };
        body = JSON.stringify(parsedBody);
      }

      const response = await fetch(`${baseUrl}/invoice/item`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body,
      });

      if (response.status === 200) {
        const { data: { itemId } } = await response.json();
        if (payload) {
          console.log('payload', payload);
          if (!payload.id) {
            const item = { ...payload, id: itemId };
            commit('appendInvoiceItems', item);
          } else {
            const { id: previousItemId } = payload;
            const item = { ...payload, id: itemId };
            commit('replaceInvoiceItems', { payload: item, previousItemId });
          }
        } else {
          parsedBody.id = itemId;
          commit('appendInvoiceItems', parsedBody);
        }
      } else {
        throw new Error('Invoice item cannot be added.');
      }
    },
    async createInvoice({ commit, getters, state }, chargeId) {
      const { invoiceeName } = state;
      const items = state.invoiceItems.map((item) => item.id);
      const response = await fetch(`${baseUrl}/invoice/${chargeId}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          invoiceeName,
          items,
        }),
      });

      if (response.status === 201) {
        const { data: { invoiceId } } = await response.json();
        commit('setInvoiceId', invoiceId);
        commit('setChargeInvoiceId', { invoiceId, chargeId, invoiceeName });
      } else {
        throw new Error('Invoice cannot be created');
      }
    },
  },
};

export default invoiceDetail;

import { baseUrl } from '@/common';

const orderDetail = {
  state: () => ({
    order: undefined,
    orderId: undefined,
    amount: undefined,
    items: undefined,
    isTicketViewEnabled: false,
  }),
  getters: {
    getOrderId: ({ orderId }) => orderId,
  },
  mutations: {
    setAmount(state, amount) {
      state.amount = amount;
    },
    setOrderId(state, orderId) {
      state.orderId = orderId;
    },
    resetItems(state) {
      state.items = undefined;
    },
    setItems(state, items) {
      state.items = items;
    },
    setOrder(state, order) {
      state.order = order;
    },
    setViewTicket(state, isTicketViewEnabled) {
      state.isTicketViewEnabled = isTicketViewEnabled;
    },
    appendTicket(state, ticket) {
      const localTickets = state.order.Tickets;
      localTickets.push({
        createdAt: new Date(),
        ...ticket,
      });
      state.order = { ...state.order, Tickets: localTickets };
    },
    syncLocalOrder(state, payload) {
      const localOrder = Object.keys(payload).reduce((order, key) => {
        // eslint-disable-next-line no-param-reassign
        order[key] = payload[key];
        return order;
      }, state.order);
      state.order = localOrder;
    },
    setCardComment(state, comment) {
      const cardIndex = state.order.Cards.findIndex(({ id }) => id === comment.commentableId);
      const localComments = state.order.Cards[cardIndex].GenComments;
      const localCards = state.order.Cards;
      localCards[cardIndex].GenComments = [...localComments, comment];
      state.order = { ...state.order, Cards: localCards };
    },
    setTestComment(state, comment) {
      const testIndex = state.order.Tests.findIndex(({ id }) => id === comment.commentableId);
      const localComments = state.order.Cards[testIndex].GenComments;
      const localTests = state.order.Tests;
      localTests[testIndex].GenComments = [...localComments, comment];
      state.order = { ...state.order, Tests: localTests };
    },
    setOnlineCourseComment(state, comment) {
      const onlineCourseIndex = state.order.OnlineCourses.findIndex(
        ({ id }) => id === comment.commentableId,
      );
      const localComments = state.order.OnlineCourses[onlineCourseIndex].GenComments;
      const localOnlineCourses = state.order.OnlineCourses;
      localOnlineCourses[onlineCourseIndex].GenComments = [...localComments, comment];
      state.order = { ...state.order, OnlineCourses: localOnlineCourses };
    },
    setOfflineCourseSeatComment(state, comment) {
      const offlineCourseSeatIndex = state.order.OfflineCourseSeats.findIndex(
        ({ id }) => id === comment.commentableId,
      );
      const localComments = state.order.OfflineCourseSeats[offlineCourseSeatIndex].GenComments;
      const localOfflineCourseSeats = state.order.OfflineCourseSeats;
      localOfflineCourseSeats[offlineCourseSeatIndex].GenComments = [...localComments, comment];
      state.order = { ...state.order, OfflineCourseSeats: localOfflineCourseSeats };
    },
    updateCharge(state, chargeId) {
      const localPayment = state.order.Payment ? state.order.Payment : {};
      localPayment.Charge = {
        externalRefId: chargeId,
        createAt: new Date(),
      };
      state.order = { ...state.order, Payment: localPayment };
    },
  },
  actions: {
    setAmount({ commit }, payload) {
      commit('setAmount', payload);
    },
    setOrderId({ commit }, payload) {
      commit('setOrderId', payload);
    },
    setItems({ commit }, payload) {
      commit('setItems', payload);
    },
    resetItems({ commit }, payload) {
      commit('resetItems', payload);
    },
    resetOrder({ commit }) {
      commit('setOrder', undefined);
    },
    setViewTicket({ commit }, payload) {
      commit('setViewTicket', payload);
    },
    async addOrder({ commit, state, getters }) {
      const response = await fetch(`${baseUrl}/order`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          userId: getters.getUserId,
          amount: state.amount,
          items: state.items,
        }),
      });

      const { data: { orderId } } = await response.json();

      commit('setOrderId', orderId);
    },
    async updateOrderDetail({ commit, state, getters }, payload) {
      const response = await fetch(`${baseUrl}/order/${state.orderId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify(payload),
      });

      if (response.status !== 204) {
        throw new Error('Request to server failed');
      }
      commit('syncLocalOrder', payload);
    },
    async deleteOrder({ getters, commit }, orderId) {
      const response = await fetch(`${baseUrl}/order/${orderId}`, {
        method: 'DELETE',
        headers: {
          Authorization: getters.getCredentialString,
        },
      });

      if (response.status !== 204) {
        throw new Error('Request to server failed');
      }

      commit('removeOrderFromUser', orderId);
    },
    async addTicketToOrder({ commit, state, getters }, payload) {
      const ticketContent = {
        orderId: state.order.id,
        comment: payload.content,
        agentId: payload.agentId,
      };
      const response = await fetch(`${baseUrl}/order/ticket`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify(ticketContent),
      });

      if (response.status === 201) {
        const { data: { ticketId } } = await response.json();
        commit('appendTicket', { id: ticketId, status: 'Open', ...ticketContent });
      }
    },
    async updateStripeChargeId({ commit, state, getters }, payload) {
      const response = await fetch(`${baseUrl}/order/charge/${state.orderId}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          externalRefId: payload,
        }),
      });

      if (response.status === 204) {
        commit('updateCharge', payload);
      }
    },
    async getOrder({ commit, state, getters }) {
      const response = await fetch(`${baseUrl}/order/${state.orderId}`, {
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
      });

      const { data: { order } } = await response.json();

      commit('setOrder', order);
      commit('setAmount', order.amount);
      commit('setItems', order.items);
    },
    async updateAmount({ commit, state, getters }, amount) {
      const response = await fetch(`${baseUrl}/order/${state.orderId}`, {
        mode: 'cors',
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          amount,
        }),
      });

      if (response.status === 204) {
        commit('setAmount', amount);
      }
    },
    async emailTracking({ state, getters }) {
      const response = await fetch(`${baseUrl}/order/tracking/${state.order.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
      });

      if (response.status === 204) {
        return true;
      }

      throw new Error(response.status);
    },

  },
};

export default orderDetail;

import { baseUrl } from '@/common';

const ordersView = {
  state: () => ({
    orders: [],
    page: 1,
    isUnassigned: false,
    timespan: 'ALL',
    count: undefined,
    success: true,
  }),
  mutations: {
    setOrders(state, orders) {
      state.orders = orders;
    },
    setOrdersViewPage(state, page) {
      state.page = page;
    },
    setIsUnassigned(state, isUnassigned) {
      state.isUnassigned = isUnassigned;
    },
    setCount(state, count) {
      state.count = count;
    },
    setSuccess(state, success) {
      state.success = success;
    },
    setOrderViewTimespan(state, timespan) {
      state.timespan = timespan;
    },
  },
  actions: {
    setSuccess({ commit }, payload) {
      commit('setSuccess', payload);
    },
    toggleIsUnassigned({ commit, state }) {
      commit('setIsUnassigned', !state.isUnassigned);
    },
    setOrderViewTimespan({ commit }, payload) {
      commit('setOrderViewTimespan', payload);
    },
    updateOrdersViewPage({ commit, state }, payload) {
      if (payload === 'INCREMENT') {
        commit('setOrdersViewPage', state.page + 1);
      } else if (payload === 'DECREMENT') {
        if (state.page > 1) {
          commit('setOrdersViewPage', state.page - 1);
        } else {
          commit('setOrdersViewPage', 1);
        }
      } else if (typeof payload === 'number') {
        commit('setOrdersViewPage', payload);
      }
    },
    async queryOrders({ state, getters, commit }) {
      const response = await fetch(
        `${baseUrl}/order?page=${state.page}&success=${state.success}&unassigned=${state.isUnassigned}&timespan=${state.timespan}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getters.getCredentialString,
          },
        },
      );

      if (response.status === 400) {
        throw new Error('Incorrect data');
      }

      if (response.status === 200) {
        const { data: { orders, count } } = await response.json();
        commit('setOrders', orders);
        commit('setCount', count);
      }
    },
  },
};

export default ordersView;

<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-full">
        <div :class="stubbed ? 'box block' : ''">
          <div class="columns is-centered">
            <div class="column is-6">
              <h1 class="title mt-3">CARDS</h1>
            <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <h3 class="is-size-5 p-3"><b>Page:</b> {{ page }}</h3>
          </div>
        </div>

        <div class="level-right">

          <p class="level-item">
            <b-dropdown
                    position="is-bottom-left"
                    append-to-body
                    aria-role="menu"
                    trap-focus
                >
                    <template #trigger>
                        <a
                            class="navbar-item"
                            type="is-info"
                            role="button">
                            <span>Filter</span>
                            <b-icon icon="menu-down"></b-icon>
                        </a>
                    </template>

                    <b-dropdown-item
                        aria-role="menu-item"
                        :focusable="false"
                        custom
                        paddingless>
                        <form action="">
                            <div class="modal-card" style="width:300px;">
                                <section class="modal-card-body">
                                  <b-field>
                                        <b-switch
                                            :value="sort === 'updatedAt'"
                                            @input="changeSortBy"
                                            passive-type="is-dark"
                                            type="is-warning"
                                          >
                                            {{ sort === 'updatedAt' ? "UPDATED" : "DUE DATE" }}
                                          </b-switch>
                                      </b-field>
                                    <b-collapse :open="false" aria-id="contentIdForA11y1">
                                      <template #trigger="props">
                                          <b-button
                                              label="Dates"
                                              class="mb-2"
                                              aria-controls="contentIdForA11y1"
                                              expanded
                                              :aria-expanded="props.open" />
                                      </template>
                                      <div>
                                          <b-datepicker
                                            @input="updateMinDate"
                                            size="is-small"
                                            placeholder="Start Date"
                                            icon="calendar-today"
                                            :icon-right="selected ? 'close-circle' : ''"
                                            trap-focus>
                                        </b-datepicker>
                                        <br>
                                        <b-datepicker
                                            @input="updateMaxDate"
                                            :min-date="new Date(minDate)"
                                            size="is-small"
                                            placeholder="End Date"
                                            icon="calendar-today"
                                            :icon-right="selected ? 'close-circle' : ''"
                                            trap-focus>
                                        </b-datepicker>
                                      </div>
                                  </b-collapse>
                                  <div class="field-body">
                                    <div class="field mt-3">
                                      <b-field>
                                        <b-select
                                          @input="updateCardApplicationType"
                                          :value="appType"
                                          placeholder="All"
                                          id="application-type"
                                          ref="application-type"
                                          expanded
                                        >
                                          <option value="New">
                                            New Card
                                          </option>
                                          <option value="Renew">
                                            Renew Card
                                          </option>
                                          <option value="Lost/Stolen">
                                            Lost/Stolen
                                          </option>
                                        </b-select>
                                      </b-field>
                                    </div>
                                  </div>
                                  <b-button
                                        label="Filter"
                                        expanded
                                        @click="queryCardsUI"
                                        class="mt-4"
                                        type="is-info is-light" />
                                </section>
                            </div>
                        </form>
                    </b-dropdown-item>
                </b-dropdown>
          </p>

          <p class="level-item">
            <b-button @click="downloadData" type="is-info">Download</b-button>
          </p>
        </div>
      </nav>
            </div>
          </div>
          <p class="panel-tabs">
            <a :class="isAllActive" @click="getAll">All</a>
            <a :class="isSelfActive" @click="getSelf">Self</a>
            <a
              v-bind:class="isStatusActive('Open') ? 'is-active' : ''"
              @click="() => getCardByStatus('Open')"
              >Open
              <br>
              {{ isStatusActive('Open') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('To Be Booked') ? 'is-active' : ''"
              @click="() => getCardByStatus('To Be Booked')"
              >To Be booked
              <br>
              {{ isStatusActive('To Be Booked') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('First Contact') ? 'is-active' : ''"
              @click="() => getCardByStatus('First Contact')"
              >First Contact
              <br>
              {{ isStatusActive('First Contact') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('Called Customer') ? 'is-active' : ''"
              @click="() => getCardByStatus('Called Customer')"
              >Called Customer
              <br>
              {{ isStatusActive('Called Customer') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('No Response') ? 'is-active' : ''"
              @click="() => getCardByStatus('No Response')"
              >No Response
              <br>
              {{ isStatusActive('No Response') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('Ongoing Verification') ? 'is-active' : ''"
              @click="() => getCardByStatus('Ongoing Verification')"
            >
              Ongoing Verification
              <br>
              {{ isStatusActive('Ongoing Verification') ? count : '' }}
            </a>
            <a
              v-bind:class="isStatusActive('Course Pending') ? 'is-active' : ''"
              @click="() => getCardByStatus('Course Pending')"
            >
              Course Pending
              <br>
              {{ isStatusActive('Course Pending') ? count : '' }}
            </a>
            <a
              v-bind:class="isStatusActive('Document Verified') ? 'is-active' : ''"
              @click="() => getCardByStatus('Document Verified')"
              >Document Verified
              <br>
              {{ isStatusActive('Document Verified') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('Processing') ? 'is-active' : ''"
              @click="() => getCardByStatus('Processing')"
              >Processing
              <br>
              {{ isStatusActive('Processing') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('Customer Dispute') ? 'is-active' : ''"
              @click="() => getCardByStatus('Customer Dispute')"
              >Customer Dispute
              <br>
              {{ isStatusActive('Customer Dispute') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('CSCS Dispute') ? 'is-active' : ''"
              @click="() => getCardByStatus('CSCS Dispute')"
              >CSCS Dispute
              <br>
              {{ isStatusActive('CSCS Dispute') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('Refund Request') ? 'is-active' : ''"
              @click="() => getCardByStatus('Refund Request')"
              >Refund Request
              <br>
              {{ isStatusActive('Refund Request') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('Approved') ? 'is-active' : ''"
              @click="() => getCardByStatus('Approved')"
              >Approved
              <br>
              {{ isStatusActive('Approved') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('Cancelled') ? 'is-active' : ''"
              @click="() => getCardByStatus('Cancelled')"
              >Cancelled
              <br>
              {{ isStatusActive('Cancelled') ? count : '' }}
              </a
            >
            <a
              v-bind:class="isStatusActive('Overdue') ? 'is-active' : ''"
              @click="() => getCardByStatus('Overdue')"
              >Overdue</a
            >
            <a
              v-bind:class="isStatusActive('Ice Box') ? 'is-active' : ''"
              @click="() => getCardByStatus('Ice Box')"
              >ICE Box
              <br>
              {{ isStatusActive('Ice Box') ? count : '' }}
              </a
            >
          </p>
        <div v-if="isLoading">
          <Loading />
        </div>
          <table v-else class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Email</th>
                <th>Type</th>
                <th>Due Date</th>
                <th v-if="agent.type === 'Admin'">Process by</th>
                <th v-if="!filter">Status</th>
              </tr>
            </thead>
            <tbody v-if="cards.length">
              <tr
                @click="navigateToOrder(card.orderId)"
                v-for="card in netCards"
                :key="card.id"
                v-bind:class="getClassByCardType(card.type)"
              >
                <td>
                  {{ new Date(card.createdAt).toDateString() }}
                </td>
                <td>{{ card.Order.User.title }}
                  {{ card.Order.User.firstName }} {{ card.Order.User.lastName }}
                </td>
                <td>{{ card.Order.User.email || "No email" }}</td>
                <td>{{ card.type }}
                  <b-tag type="is-info is-light">{{card.applicationType}}</b-tag>
                </td>
                <td>{{ new Intl.DateTimeFormat('en-GB', {
                    dateStyle: 'full', timeZone: "Europe/London"
                  }).format(new Date( card.dueDate )).split('BST')[0] }}
                </td>
                <td v-if="agent.type === 'Admin'">
                  <span v-if="card.Order.backendAgentId">
                    <b-tag type="is-light is-primary">
                      {{ getAgentById(card.Order.backendAgentId)}}
                    </b-tag>
                  </span>
                  <span v-else>
                    <b-tag type="is-light is-primary">
                      N/A
                   </b-tag>
                  </span>
                </td>
                <td v-if="!filter">
                  {{ card.status }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!stubbed" class="is-flex-direction-row">
            <b-button
              class="is-justify-content-flex-start"
              @click="decrementPage"
              :disabled="page === 1"
              >Prev</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateCardsViewPage(1);
                  queryCards();
                }
              "
              >1</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateCardsViewPage(5);
                  queryCards();
                }
              "
              >5</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateCardsViewPage(10);
                  queryCards();
                }
              "
              >10</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateCardsViewPage(20);
                  queryCards();
                }
              "
              >20</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateCardsViewPage(30);
                  queryCards();
                }
              "
              >30</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateCardsViewPage(40);
                  queryCards();
                }
              "
              >40</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateCardsViewPage(50);
                  queryCards();
                }
              "
              >50</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateCardsViewPage(60);
                  queryCards();
                }
              "
              >60</b-button
            >
            <b-button class="is-justify-content-flex-end" @click="incrementPage"
              >Next</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from '@/components/Loading.vue';

import { navigateToOrder, getClassByCardType, getAgentById } from '@/common';

export default {
  name: 'CardList',
  data: () => ({
    isLoading: true,
    selected: null,
  }),
  props: {
    stubbed: Boolean,
  },
  async mounted() {
    try {
      await this.queryCards();
    } catch (error) {
      console.error(error);
      this.$router.push({ name: 'Login' });
    } finally {
      this.isLoading = false;
    }
    return null;
  },
  components: {
    Loading,
  },
  methods: {
    ...mapActions([
      'queryCards',
      'updateCardsViewPage',
      'setCardsViewFilter',
      'setCardsViewSort',
      'setCardsViewFilterStatus',
      'setAppType',
      'setMinDate',
      'setMaxDate',
    ]),
    navigateToOrder,
    getClassByCardType,
    getAgentById,
    downloadData() {
      return this.queryCards(true);
    },
    isStatusActive(status) {
      return this.filter === 'status' && this.status === status;
    },
    async decrementPage() {
      this.updateCardsViewPage('DECREMENT');
      this.isLoading = true;
      try {
        await this.queryCards();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async incrementPage() {
      this.updateCardsViewPage('INCREMENT');
      this.isLoading = true;
      try {
        await this.queryCards();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async queryCardsUI() {
      this.isLoading = true;
      try {
        await this.queryCards();
      } catch (error) {
        console.error(error);
        this.$router.push({ name: 'Home' });
      } finally {
        this.isLoading = false;
      }
    },
    async changeSortBy(value) {
      if (value) {
        this.setCardsViewSort('updatedAt');
      } else {
        this.setCardsViewSort('dueDate');
      }
    },
    async getAll() {
      this.setCardsViewFilter(null);
      this.setCardsViewFilterStatus(null);

      this.isLoading = true;
      try {
        await this.queryCards();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getSelf() {
      this.setCardsViewFilter('self');
      this.setCardsViewFilterStatus(null);

      this.isLoading = true;
      try {
        await this.queryCards();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getCardByStatus(status) {
      this.setCardsViewFilter('status');
      this.setCardsViewFilterStatus(status);

      this.isLoading = true;
      try {
        await this.queryCards();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    updateCardApplicationType(applicationType) {
      this.setAppType(applicationType);
    },
    updateMinDate(minDate) {
      this.setMinDate(minDate);
    },
    updateMaxDate(maxDate) {
      this.setMaxDate(maxDate);
    },

  },
  computed: {
    ...mapState({
      cards: ({ cardsView: { cards } }) => cards,
      count: ({ cardsView: { count } }) => count,
      page: ({ cardsView: { page } }) => page,
      status: ({ cardsView: { status } }) => status,
      sort: ({ cardsView: { sort } }) => sort,
      filter: ({ cardsView: { filter } }) => filter,
      appType: ({ cardsView: { appType } }) => appType,
      minDate: ({ cardsView: { minDate } }) => minDate,
      maxDate: ({ cardsView: { maxDate } }) => maxDate,
      agent: ({ agentData: { agent } }) => agent,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
    netCards() {
      if (this.stubbed) {
        return this.cards && this.cards.slice(0, 6);
      }
      return this.cards;
    },
    isAllActive() {
      return this.filter !== 'self' && this.filter !== 'status'
        ? 'is-active'
        : '';
    },
    isSelfActive() {
      return this.filter === 'self' ? 'is-active' : '';
    },
  },
};
</script>
<style scoped>
table {
  cursor: pointer;
}
</style>

<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-full">
        <div v-if="isLoading">
          <Loading />
        </div>
        <div :class="stubbed ? 'box block' : ''" v-else>
          <div class="mt-6 mb-6">
            <ProductSummary></ProductSummary>
          </div>
          <table v-if="!isLoading" class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>Amount</th>
                <th v-if="agent.type === 'Admin'">Sale by</th>
                <th v-if="agent.type === 'Admin'">Process by</th>
                <th>Name</th>
                <th>Email</th>
                <th>Date</th>
                <th>Product</th>
              </tr>
            </thead>
            <tbody v-if="orders.length">

              <tr @click="navigateToOrder(order)"
                v-for="order in netOrders" :key="order.id"
                :class="getClassByChargeStatus(order)"
              >
                <td class="has-text-weight-bold">
                  £{{ order.amount/100 }}
                </td>
                <td v-if="agent.type === 'Admin'">
                  <span v-if="order.agentId">
                    <b-tag type="is-light is-primary">
                      {{ getAgentById(order.agentId)}}
                    </b-tag>
                  </span>
                  <span v-else>
                    <b-tag type="is-light is-primary">
                      N/A
                   </b-tag>
                  </span>
                </td>
                <td v-if="agent.type === 'Admin'">
                  <span v-if="order.backendAgentId">
                    <b-tag type="is-light is-primary">
                      {{ getAgentById(order.backendAgentId)}}
                    </b-tag>
                  </span>
                  <span v-else>
                    <b-tag type="is-light is-primary">
                      N/A
                   </b-tag>
                  </span>
                </td>
                <td>{{ order.User.firstName }} {{order.User.lastName}}</td>
                <td>{{ order.User.email }}</td>
                <td>{{ getHumanDate(order.createdAt, true) }}</td>
                <td>
                    <span v-for="(item, index) in order.items" :key="index">
                        <b-tag class="mx-1">{{ item }}</b-tag>
                    </span>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination></Pagination>
          <!-- <div v-if="!stubbed" class="is-flex-direction-row">
            <b-button
              class="is-justify-content-flex-start"
              @click="decrementPage"
              :disabled="page === 1"
              >Prev</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateOrdersViewPage(1);
                  queryOrders();
                }
              "
              >1</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateOrdersViewPage(5);
                  queryOrders();
                }
              "
              >5</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateOrdersViewPage(10);
                  queryOrders();
                }
              "
              >10</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateOrdersViewPage(20);
                  queryOrders();
                }
              "
              >20</b-button
            >
            <b-button class="is-justify-content-flex-end" @click="incrementPage"
              >Next</b-button
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from '@/components/Loading.vue';

import { navigateToOrder, getAgentById, getHumanDate } from '@/common';
import ProductSummary from './ProductSummary.vue';
import Pagination from './Pagination.vue';

export default {
  name: 'OrderList',
  data: () => ({
    isLoading: true,
  }),
  props: {
    stubbed: Boolean,
  },
  async mounted() {
    try {
      await this.queryOrders();
    } catch (error) {
      console.error(error);
      this.$router.push({ name: 'Login' });
    } finally {
      this.isLoading = false;
    }
    return null;
  },
  components: {
    Loading,
    ProductSummary,
    Pagination,
  },
  methods: {
    ...mapActions([
      'queryOrders',
      'updateOrdersViewPage',
    ]),
    navigateToOrder,
    getAgentById,
    getHumanDate,
    getClassByChargeStatus(order) {
      try {
        const { status } = order.Payment.Charge;

        if (status === 'created') {
          return 'has-text-white has-background-success';
        } if (status === 'refunded') {
          return 'has-text-white has-background-danger';
        }
        return '';
      } catch {
        return '';
      }
    },
    async decrementPage() {
      this.updateOrdersViewPage('DECREMENT');
      this.isLoading = true;
      try {
        await this.queryOrders();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async incrementPage() {
      this.updateOrdersViewPage('INCREMENT');
      this.isLoading = true;
      try {
        await this.queryOrders();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapState({
      orders: ({ ordersView: { orders } }) => orders,
      page: ({ ordersView: { page } }) => page,
      agent: ({ agentData: { agent } }) => agent,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
    netOrders() {
      if (this.stubbed) {
        return this.orders && this.orders.slice(0, 6);
      }
      return this.orders;
    },
  },
};
</script>
<style scoped>

table {
    cursor: pointer;
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/leads',
    name: 'Leads',
    component: () => import('../views/Leads.vue'),
  },
  {
    path: '/order/new',
    name: 'Order',
    props: (route) => ({
      userId: route.query.u,
      orderId: route.query.o,
    }),
    component: () => import('../views/AddOrder.vue'),
  },
  {
    path: '/orders',
    name: 'OrderList',
    component: () => import('../components/OrderList/OrderList.vue'),
  },
  {
    path: '/cards',
    name: 'CardList',
    component: () => import('../components/CardList.vue'),
  },
  {
    path: '/tests',
    name: 'TestList',
    component: () => import('../components/TestList.vue'),
  },
  {
    path: '/course/online',
    name: 'OnlineCourseList',
    component: () => import('../components/OnlineCourseList.vue'),
  },
  {
    path: '/products',
    name: 'ProductList',
    component: () => import('../components/ProductList.vue'),
  },
  {
    path: '/course/offline',
    name: 'OneDayCourseList',
    component: () => import('../components/OneDayCourseList.vue'),
  },
  {
    path: '/charges',
    name: 'ChargeList',
    component: () => import('../components/ChargeList/ChargeList.vue'),
  },
  {
    path: '/tickets',
    name: 'TicketList',
    component: () => import('../components/TicketList.vue'),
  },
  {
    path: '/agents/status',
    name: 'AgentStatus',
    component: () => import('../components/AgentStatus.vue'),
  },
  {
    path: '/agents/:agentId',
    name: 'AgentBreak',
    component: () => import('../components/AgentBreak.vue'),
  },
  {
    path: '/candidate/new',
    name: 'AddUser',
    component: () => import('../views/AddUser.vue'),
  },
  {
    path: '/candidate/:userId',
    name: 'LeadDetail',
    props: (route) => ({
      n: route.query.n,
    }),
    component: () => import('../views/LeadDetail.vue'),
  },
  {
    path: '/mail',
    name: 'Mail',
    props: (route) => ({
      email: route.query.e,
    }),
    component: () => import('../components/Forms/MailModal.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const isAuthenticated = () => {
  try {
    const agent = JSON.parse(sessionStorage.agent);
    return sessionStorage.credentialString && typeof agent === 'object' && agent.name;
  } catch (error) {
    return false;
  }
};

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !isAuthenticated()) return next({ name: 'Login' });
  return next();
});

export default router;

import { baseUrl } from '@/common';

const productDetails = {
  state: () => ({
    productId: undefined,
    name: undefined,
    shortName: undefined,
    status: undefined,
    detail: undefined,
    productAmount: undefined,
  }),
  mutations: {
    setName(state, name) {
      state.name = name;
    },
    setProductId(state, productId) {
      state.productId = productId;
    },
    setShortName(state, shortName) {
      state.shortName = shortName;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setDetail(state, detail) {
      state.detail = detail;
    },
    setProductAmount(state, productAmount) {
      state.productAmount = productAmount;
    },
  },
  actions: {
    setProductName({ commit }, payload) {
      commit('setName', payload);
    },
    setProductId({ commit }, payload) {
      commit('setProductId', payload);
    },
    setShortName({ commit }, payload) {
      commit('setShortName', payload);
    },
    setStatus({ commit }, payload) {
      commit('setStatus', payload);
    },
    setDetail({ commit }, payload) {
      commit('setDetail', payload);
    },
    setProductAmount({ commit }, payload) {
      commit('setProductAmount', payload);
    },
    async addProduct({
      commit, state, getters, dispatch,
    }) {
      const response = await fetch(`${baseUrl}/product`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          userId: getters.getUserId,
          orderId: getters.getOrderId,
          name: state.name,
          shortName: state.shortName,
          detail: state.detail,
          amount: state.productAmount,
        }),
      });

      const { data: { orderId } } = await response.json();

      commit('setOrderId', orderId);
      return dispatch('getOrder');
    },
    async updateProductDetail({
      commit, getters, state,
    }, payload) {
      const response = await fetch(`${baseUrl}/product/${state.productId}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify(payload),
      });

      const { data: { order } } = await response.json();

      commit('setOrder', order);
      commit('setAmount', order.amount);
    },
  },
};

export default productDetails;

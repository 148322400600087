<template>
    <div class="block">
        <nav v-if="backendSummary" class="level">
            <div class="box level-item has-text-centered has-background-success-light">
                <div class="columns is-vcentered" id="over-due-box">
                  <div class="column is-3">
                    <span class="icon has-text-success title">
                      <i class="fas fa-pound-sign"></i>
                    </span>
                  </div>
                  <div class="column is-10">
                    <p class="heading">Over Due</p>
                    <p class="title">{{ backendSummary.overDueCount }}</p>
                  </div>
                </div>
            </div>
            <div class="box level-item has-text-centered has-background-info-light">
                <div class="columns is-vcentered" id="incomplete-cards-box">
                  <div class="column is-3">
                    <span class="icon has-text-info title">
                      <i class="fas fa-shopping-cart"></i>
                    </span>
                  </div>
                  <div class="column is-10">
                    <p class="heading">Incomplete Cards</p>
                    <p class="title">{{ backendSummary.incompleteCardCount }}</p>
                  </div>
                </div>
            </div>
            <div class="box level-item has-text-centered has-background-danger-light">
                <div class="columns is-vcentered" id="open-tickets-box">
                  <div class="column is-3">
                    <span class="icon has-text-danger title">
                      <i class="fas fa-tag"></i>
                    </span>
                  </div>
                  <div class="column is-10">
                    <p class="heading">Open Tickets</p>
                    <p class="title">{{ backendSummary.openTickets }}</p>
                  </div>
                </div>
            </div>
            <div class="box level-item has-text-centered has-background-warning-light">
                <div class="columns is-vcentered" id="due-soon-box">
                  <div class="column is-3">
                    <span class="icon has-text-warning title">
                      <i class="fas fa-calendar-alt"></i>
                    </span>
                  </div>
                  <div class="column is-10">
                    <p class="heading">Due Soon</p>
                    <p class="title">{{ backendSummary.dueSoonCount }}</p>
                  </div>
                </div>
            </div>
        </nav>
        <div class="columns mt-5">
            <div class="column is-5">
                <div class="block-custom" v-if="cardChartData.length">
                  <b-taglist attached>
                      <span
                      v-on:click="updateDurationFilter('week')"
                      >
                        <b-tag class="is-clickable"
                          :type="cardChartFilter === 'week' ? 'is-success' : ''">
                          Weekly
                        </b-tag>
                      </span>
                      <span
                      v-on:click="updateDurationFilter('month')"
                      >
                        <b-tag class="is-clickable"
                          :type="cardChartFilter === 'month' ? 'is-success' : ''">
                        Monthly
                        </b-tag>
                      </span>
                  </b-taglist>
                  <span v-if="dataCollection">
                    <CardChart :chart-data="dataCollection" />
                  </span>
                </div>
            </div>
            <div class="is-7">
              <CardList stubbed />
            </div>
        </div>
    </div>
</template>

<script>
import CardChart from '@/components/CardChart';
import CardList from '@/components/CardList.vue';
import { mapState, mapActions } from 'vuex';

export default {
  data: () => ({
    dataCollection: null,
  }),
  methods: {
    ...mapActions([
      'getBackendChartData',
      'setCardChartFilter',
    ]),
    async updateDurationFilter(filter) {
      await this.setCardChartFilter(filter);
      this.fillData();
    },
    fillData() {
      this.dataCollection = {
        type: 'pie',
        labels: this.labels,
        datasets: [{
          label: '# of Cards',
          data: this.cardChartData,
          fill: false,
          backgroundColor: [
            '#8e571f',
            '#f2b980',
            'rgba(195, 66, 126, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            '#d5ecc2',
            '#000',
            // '#293b5f',
            '#a58faa',
            '#b2ab8c',
            'rgba(205, 19, 164, 0.2)',
            '#ffaaa7',
            '#98ddca',
            '#98ddca',
          ],
          tension: 0.1,
        }],
      };
    },
  },
  computed: {
    ...mapState({
      cardChartData: ({ home: { cardChartData } }) => cardChartData,
      cardChartFilter: ({ home: { cardChartFilter } }) => cardChartFilter,
      labels: ({ home: { labels } }) => labels,
      backendSummary: ({ home: { backendSummary } }) => backendSummary,
    }),
  },
  async mounted() {
    await this.getBackendChartData();
    this.fillData();
  },
  components: {
    CardChart,
    CardList,
  },
};
</script>

<style scoped>

.level > .level-item.box {
  margin: 1em;
}

.block-custom {
  margin: 1.9em;
}
</style>

<template>
    <Login />
</template>
<script>
import Login from '@/components/Forms/Login.vue';

export default {
  name: 'LoginView',
  components: {
    Login,
  },
};
</script>

<template>
  <div class="container">
    <div class="columns is-centered is-vcentered">
      <div class="column is-one-third">
        <div class="box">
            <div class="field">
            <label class="label">Username</label>
            <div class="control">
              <input class="input" type="text" v-model="username" v-on:keyup.enter="validate" />
            </div>
          </div>

          <div class="field">
            <label class="label">Password</label>
            <div class="control">
              <input class="input" type="password" v-model="password" v-on:keyup.enter="validate" />
            </div>
          </div>
          <div class="field">
            <button class="button is-primary" @click.prevent="validate">Login</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  data: () => ({
    username: undefined,
    password: undefined,
  }),
  methods: {
    ...mapActions(['validateCredentials']),
    createSnackbar(message) {
      return this.$buefy.toast.open({
        message,
        type: 'is-warning',
        duration: 3500,
        container: 'section',
        position: 'is-top',
      });
    },
    async validate() {
      try {
        await this.validateCredentials({
          username: this.username,
          password: this.password,
        });

        this.$router.push({ name: 'Home' });
        return null;
      } catch (error) {
        return this.createSnackbar(error.message);
      }
    },
  },
};
</script>

<style scoped>
  .columns {
    height: 90vh;
  }
</style>

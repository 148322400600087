import { baseUrl } from '@/common';

const testDetails = {
  state: () => ({
    testId: undefined,
    location: undefined,
    date: undefined,
    time: undefined,
    type: undefined,
    confirmationText: undefined,
    sessionId: undefined,
    retake: false,
    language: undefined,
  }),
  getters: {
    getLocation: ({ location }) => location,
    getDate: ({ date }) => date,
    getTime: ({ time }) => time,
    getType: ({ type }) => type,
    getSessionId: ({ sessionId }) => sessionId,
  },
  mutations: {
    setLocation(state, location) {
      state.location = location;
    },
    setDate(state, date) {
      state.date = date;
    },
    setTime(state, time) {
      state.time = time;
    },
    setType(state, type) {
      state.type = type;
    },
    setTestId(state, testId) {
      state.testId = testId;
    },
    setSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    setConfirmationText(state, confirmationText) {
      state.confirmationText = confirmationText;
    },
    setRetake(state, retake) {
      state.retake = retake;
    },
    setLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {
    setLocation({ commit }, payload) {
      commit('setLocation', payload);
    },
    setDate({ commit }, payload) {
      commit('setDate', payload);
    },
    setTime({ commit }, payload) {
      commit('setTime', payload);
    },
    setType({ commit }, payload) {
      commit('setType', payload);
    },
    setTestId({ commit }, payload) {
      commit('setTestId', payload);
    },
    setRetake({ commit }, payload) {
      commit('setRetake', payload);
    },
    setLanguage({ commit }, payload) {
      commit('setLanguage', payload);
    },
    setConfirmationText({ commit }, payload) {
      commit('setConfirmationText', payload);
    },
    async addTest({
      state, getters, commit, dispatch,
    }) {
      const response = await fetch(`${baseUrl}/test`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          userId: getters.getUserId,
          orderId: getters.getOrderId,
          location: state.location,
          date: state.date,
          testTime: state.time,
          confirmationText: state.confirmationText,
          testType: state.type,
          retake: state.retake,
          language: state.language,
        }),
      });

      const { data: { orderId } } = await response.json();
      commit('setOrderId', orderId);
      return dispatch('getOrder');
    },
    async updateTestDetails({
      commit, getters, state,
    }, payload) {
      const response = await fetch(`${baseUrl}/test/${state.testId}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify(payload),
      });

      const { data: { order } } = await response.json();

      commit('setOrder', order);
    },
    async bookTest({ getters }, payload) {
      await fetch(`${baseUrl}/test/book/${payload}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
      });
    },
    async sendEmail({ getters }, payload) {
      await fetch(`${baseUrl}/test/mail/${payload}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
      });
    },
  },
};

export default testDetails;

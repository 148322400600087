import { baseUrl, getSessionObject } from '@/common';

const oneDayCourseDetails = {
  state: () => ({
    courseId: undefined,
    locations: getSessionObject('locations'),
    locationId: undefined,
    datetime: undefined,
    trade: undefined,
    comment: undefined,
  }),
  mutations: {
    setTrait(state, trade) {
      state.trade = trade;
    },
    setCourseId(state, courseId) {
      state.courseId = courseId;
    },
    setLocationId(state, locationId) {
      state.locationId = locationId;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setDatetime(state, datetime) {
      state.datetime = datetime;
    },
    setComment(state, comment) {
      state.comment = comment;
    },
    setAllLocations(state, locations) {
      sessionStorage.locations = JSON.stringify(locations);
      state.locations = locations;
    },
  },
  actions: {
    setOneDayTrait({ commit }, payload) {
      commit('setTrait', payload);
    },
    setOneDayCourseId({ commit }, payload) {
      commit('setCourseId', payload);
    },
    setLocationId({ commit }, payload) {
      commit('setLocationId', payload);
    },
    setOneDayStatus({ commit }, payload) {
      commit('setStatus', payload);
    },
    setDatetime({ commit }, payload) {
      commit('setDatetime', payload);
    },
    setOneDayCourseComment({ commit }, payload) {
      commit('setComment', payload);
    },
    async getAllLocations({ commit, getters }) {
      const response = await fetch(`${baseUrl}/course/offline/location/all`, {
        mode: 'cors',
        headers: {
          Authorization: getters.getCredentialString,
        },
      });

      const { data: { locations } } = await response.json();
      commit('setAllLocations', locations);
    },
    async addOneDayCourse({
      commit, state, getters, dispatch,
    }) {
      const seatInfo = await fetch(`${baseUrl}/course/offline/seat`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          locationId: state.locationId,
          seatCount: 1,
          datetime: state.datetime,
          name: 'Level 1 Award in Health and Safety',
          provider: 'Hurak',
        }),
      });

      const { data: { seatIds: [seatId] } } = await seatInfo.json();

      const response = await fetch(`${baseUrl}/course/offline/book/${seatId}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          userId: getters.getUserId,
          orderId: getters.getOrderId,
        }),
      });

      const { data: { orderId } } = await response.json();
      commit('setOrderId', orderId);
      return dispatch('getOrder');
    },
    async updateOneDayCourseDetail({
      commit, getters, state,
    }, payload) {
      const response = await fetch(`${baseUrl}/course/offline/${state.courseId}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify(payload),
      });

      const { data: { order } } = await response.json();

      commit('setOrder', order);
    },
  },
};

export default oneDayCourseDetails;

<template>
  <nav
    class="navbar has-background-light"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
      <a class="navbar-item" href="/">
        <img
          id="navbar-cs-logo"
          src="../assets/logo.png"
          alt="Dashboard Logo"
        />
      </a>
    </div>

    <div class="navbar-menu">
      <div class="navbar-start">
        <span class="navbar-item">
          <LeadSearch />
        </span>
      </div>

      <div v-if="agent" class="navbar-end">
        <div class="navbar-item">
          <router-link class="flex-align-center" to="/candidate/new">
            <a class="navbar-item" id="new-candidate">
              <span class="icon">
                <i class="fas fa-plus-circle"></i>
              </span>
              New Candidate
            </a>
          </router-link>
        </div>
        <div class="navbar-item">
          <router-link :to="`/agents/${agent.id}`">
          <b>{{agent.name}}</b>
            {{timeKeeper}}
          </router-link>
        </div>
        <div class="navbar-item">
          <div class="buttons">
            <button
              id="sign-out"
              @click="signOut"
              class="button is-danger is-rounded">
              <span class="icon is-small">
                <i class="fas fa-sign-out-alt"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LeadSearch from '@/components/LeadSearch.vue';
import { wsBaseUrl, getSessionObject, getTypeByStatus } from '@/common';

export default {
  data: () => ({
    elapsedTime: 0,
    loginStatus: '',
    loginTimer: null,
  }),
  mounted() {
    this.connection.onmessage = ({ data }) => {
      const evtData = JSON.parse(data);

      if (evtData.createdAt) {
        this.elapsedTime = new Date() - new Date(evtData.createdAt);

        if (this.loginTimer === null) {
          this.loginTimer = setInterval(() => {
            this.elapsedTime += 5000;
          }, 5000);
        }
      }

      if (evtData.type === 'AGENT_STATUS_UPDATE') {
        this.updateAgentLoginStatus({
          id: evtData.id,
          status: evtData.status,
        });
      }
      // if (evtData.breakId) {
      //   sessionStorage.breakId = JSON.stringify(evtData.breakId);
      //   this.loginStatus = 'Offline';
      // } else if (evtData.breakOver) {
      //   sessionStorage.breakId = undefined;
      //   this.elapsedTime = new Date() - new Date(getSessionObject('loginCreatedAt'));
      //   console.log('Breka is over');
      // }
    };
  },
  computed: {
    ...mapState({
      agent: ({ agentData: { agent } }) => agent,
      connection: ({ primary: { connection } }) => connection,
    }),
    timeKeeper() {
      const initialElapsedMins = (this.elapsedTime / 60000).toFixed(0);
      let elapsedHours = Math.floor(initialElapsedMins / 60);
      if (elapsedHours < 10) elapsedHours = `0${elapsedHours}`;
      let elapsedMins = (initialElapsedMins % 60).toFixed(0);
      if (elapsedMins < 10) elapsedMins = `0${elapsedMins}`;
      return `${elapsedHours}:${elapsedMins}`;
    },
  },
  created() {
    this.connectToWs();
    this.connection.onopen = (event) => {
      this.loginStatus = 'Online';
      console.log('OnOpen', event);
    };

    this.connection.onclose = async (event) => {
      console.log('Connection is closed', event);
      await this.signOut();
    };
  },
  methods: {
    ...mapActions(['setCredentialString', 'setConnection', 'updateAgentLoginStatus']),
    getTypeByStatus,
    connectToWs() {
      this.setConnection(new WebSocket(`${wsBaseUrl}/login/conn?c=${getSessionObject('loginToken')}`, [], {
        handshakeTimeout: 1000 * 60 * 20,
      }));
    },
    async signOut() {
      sessionStorage.credentialString = undefined;
      sessionStorage.agent = undefined;
      this.setCredentialString(undefined);
      this.$router.push({ name: 'Login' });
      this.connection.send(JSON.stringify({
        logout: true,
      }));
    },
  },
  components: {
    LeadSearch,
  },
};
</script>

<style scoped>
img {
  width: 98px;
  min-height: 64px;
}

span.navbar-item {
  min-width: 700px;
  margin-left: 4em;
}

span.icon {
  padding-right: 0.45em;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.button .icon:first-child:last-child {
    margin-left: calc(-0.5em - 1px);
    margin-right: calc(-1.0em - 1px);
}
</style>

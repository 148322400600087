<template>
    <b-pagination
    :total="total"
    v-model="page"
    @change="
        () => {
            updateOrdersViewPage(page);
            queryOrders();
        }
    "
    :range-before="rangeBefore"
    :range-after="rangeAfter"
    :order="order"
    :simple="isSimple"
    :rounded="isRounded"
    :per-page="perPage"
    :icon-prev="prevIcon"
    :icon-next="nextIcon"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    :page-input="hasInput"
    :page-input-position="inputPosition"
    :debounce-page-input="inputDebounce">
    </b-pagination>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  mounted() {
    if (this.orderPage) {
      this.page = this.orderPage;
    }
  },
  data() {
    return {
      page: 1,
      total: 1000,
      current: 10,
      perPage: 10,
      rangeBefore: 7,
      rangeAfter: 7,
      order: 'is-centered',
      isSimple: false,
      isRounded: false,
      hasInput: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      inputPosition: '',
      inputDebounce: '',
    };
  },
  computed: {
    ...mapState({
      orderPage: ({ ordersView: { page } }) => page,
    }),
  },
  methods: {
    ...mapActions([
      'queryOrders',
      'updateOrdersViewPage',
    ]),
  },
};
</script>

import { baseUrl } from '@/common';

const cardsView = {
  state: () => ({
    cards: [],
    count: undefined,
    minDate: null,
    maxDate: null,
    page: 1,
    filter: null,
    sort: 'updatedAt',
    status: null,
    appType: null,
  }),
  mutations: {
    setCards(state, cards) {
      state.cards = cards;
    },
    setCount(state, count) {
      state.count = count;
    },
    setCardsViewPage(state, page) {
      state.page = page;
    },
    setCardsViewFilter(state, filter) {
      state.filter = filter;
    },
    setCardsViewSort(state, sort) {
      state.sort = sort;
    },
    setCardsViewFilterStatus(state, status) {
      state.status = status;
    },
    setAppType(state, appType) {
      state.appType = appType;
    },
    setMinDate(state, minDate) {
      state.minDate = minDate;
    },
    setMaxDate(state, maxDate) {
      state.maxDate = maxDate;
    },
  },
  actions: {
    setCardsViewFilter({ commit }, payload) {
      commit('setCardsViewFilter', payload);
    },
    setCardsViewSort({ commit }, payload) {
      commit('setCardsViewSort', payload);
    },
    setCardsViewFilterStatus({ commit }, payload) {
      commit('setCardsViewFilterStatus', payload);
    },
    setAppType({ commit }, payload) {
      commit('setAppType', payload);
    },
    setMinDate({ commit }, payload) {
      commit('setMinDate', payload);
    },
    setMaxDate({ commit }, payload) {
      commit('setMaxDate', payload);
    },
    updateCardsViewPage({ commit, state }, payload) {
      if (payload === 'INCREMENT') {
        commit('setCardsViewPage', state.page + 1);
      } else if (payload === 'DECREMENT') {
        if (state.page > 1) {
          commit('setCardsViewPage', state.page - 1);
        } else {
          commit('setCardsViewPage', 1);
        }
      } else if (typeof payload === 'number') {
        commit('setCardsViewPage', payload);
      }
    },
    async queryCards({ state, getters, commit }, toDownload) {
      const response = await fetch(
        // &min_date=${state.minDate}
        // &max_date=${state.maxDate}&app_type=${state.appType}
        `${baseUrl}/card?page=${state.page}&sort=${state.sort}${state.filter ? `&filter=${state.filter}` : ''}${state.status ? `&status=${state.status}` : ''}${state.appType ? `&app_type=${state.appType}` : ''}${state.minDate ? `&min_date=${state.minDate}` : ''}${state.maxDate ? `&max_date=${state.maxDate}` : ''}${toDownload ? `&download=${toDownload}` : ''}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getters.getCredentialString,
          },
        },
      );

      if (response.status === 400) {
        throw new Error('Incorrect data');
      }

      if (response.status === 200) {
        const { data: { cards, count } } = await response.json();
        commit('setCards', cards);
        commit('setCount', count);
      }
    },
  },
};

export default cardsView;

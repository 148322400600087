import { baseUrl } from '@/common';

const onlineCoursesView = {
  state: () => ({
    onlineCourses: [],
    page: 1,
    filter: null,
    sort: 'updatedAt',
    status: null,
  }),
  mutations: {
    setOnlineCourses(state, onlineCourses) {
      state.onlineCourses = onlineCourses;
    },
    setOnlineCoursesViewPage(state, page) {
      state.page = page;
    },
    setOnlineCoursesViewFilter(state, filter) {
      state.filter = filter;
    },
    setOnlineCoursesViewSort(state, sort) {
      state.sort = sort;
    },
    setOnlineCoursesViewFilterStatus(state, status) {
      state.status = status;
    },
  },
  actions: {
    setOnlineCoursesViewFilter({ commit }, payload) {
      commit('setOnlineCoursesViewFilter', payload);
    },
    setOnlineCoursesViewSort({ commit }, payload) {
      commit('setOnlineCoursesViewSort', payload);
    },
    setOnlineCoursesViewFilterStatus({ commit }, payload) {
      commit('setOnlineCoursesViewFilterStatus', payload);
    },
    updateOnlineCoursesViewPage({ commit, state }, payload) {
      if (payload === 'INCREMENT') {
        commit('setOnlineCoursesViewPage', state.page + 1);
      } else if (payload === 'DECREMENT') {
        if (state.page > 1) {
          commit('setOnlineCoursesViewPage', state.page - 1);
        } else {
          commit('setOnlineCoursesViewPage', 1);
        }
      } else if (typeof payload === 'number') {
        commit('setOnlineCoursesViewPage', payload);
      }
    },
    async queryOnlineCourses({ state, getters, commit }) {
      const response = await fetch(
        `${baseUrl}/course/online?page=${state.page}&sort=${state.sort}${state.filter ? `&filter=${state.filter}` : ''}${state.status ? `&status=${state.status}` : ''}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getters.getCredentialString,
          },
        },
      );

      if (response.status === 400) {
        throw new Error('Incorrect data');
      }

      if (response.status === 200) {
        const { data: { onlineCourses } } = await response.json();
        commit('setOnlineCourses', onlineCourses);
      }
    },
  },
};

export default onlineCoursesView;

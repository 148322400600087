import { baseUrl } from '@/common';

const productsView = {
  state: () => ({
    products: [],
    page: 1,
    filter: null,
    sort: 'updatedAt',
    status: null,
  }),
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setProductsViewPage(state, page) {
      state.page = page;
    },
    setProductsViewFilter(state, filter) {
      state.filter = filter;
    },
    setProductsViewSort(state, sort) {
      state.sort = sort;
    },
    setProductsViewFilterStatus(state, status) {
      state.status = status;
    },
  },
  actions: {
    setProductsViewFilter({ commit }, payload) {
      commit('setProductsViewFilter', payload);
    },
    setProductsViewSort({ commit }, payload) {
      commit('setProductsViewSort', payload);
    },
    setProductsViewFilterStatus({ commit }, payload) {
      commit('setProductsViewFilterStatus', payload);
    },
    updateProductsViewPage({ commit, state }, payload) {
      if (payload === 'INCREMENT') {
        commit('setProductsViewPage', state.page + 1);
      } else if (payload === 'DECREMENT') {
        if (state.page > 1) {
          commit('setProductsViewPage', state.page - 1);
        } else {
          commit('setProductsViewPage', 1);
        }
      } else if (typeof payload === 'number') {
        commit('setProductsViewPage', payload);
      }
    },
    async queryProducts({ state, getters, commit }) {
      const response = await fetch(
        `${baseUrl}/product?page=${state.page}&sort=${state.sort}${state.filter ? `&filter=${state.filter}` : ''}${state.status ? `&status=${state.status}` : ''}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getters.getCredentialString,
          },
        },
      );

      if (response.status === 400) {
        throw new Error('Incorrect data');
      }

      if (response.status === 200) {
        const { data: { products } } = await response.json();
        commit('setProducts', products);
      }
    },
  },
};

export default productsView;

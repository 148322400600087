import { baseUrl } from '@/common';

const ticketsView = {
  state: () => ({
    tickets: [],
    page: 1,
    filter: null,
  }),
  mutations: {
    setTickets(state, tickets) {
      state.tickets = tickets;
    },
    setTicketsViewPage(state, page) {
      state.page = page;
    },
    setTicketsViewFilter(state, filter) {
      state.filter = filter;
    },
    changeTicket(state, ticket) {
      const localTickets = state.tickets;
      const ticketIndex = localTickets.findIndex((_ticket) => _ticket.id === ticket.id);
      localTickets[ticketIndex].status = ticket.status;
      state.tickets = localTickets;
    },
  },
  actions: {
    updateTicketViewPage({ commit, state }, payload) {
      if (payload === 'INCREMENT') {
        commit('setTicketsViewPage', state.page + 1);
      } else if (payload === 'DECREMENT') {
        if (state.page > 1) {
          commit('setTicketsViewPage', state.page - 1);
        } else {
          commit('setTicketsViewPage', 1);
        }
      } else if (typeof payload === 'number') {
        commit('setTicketsViewPage', payload);
      }
    },
    setTicketsViewFilter({ commit }, payload) {
      commit('setTicketsViewFilter', payload);
    },
    async queryTickets({ state, getters, commit }) {
      const response = await fetch(`${baseUrl}/ticket?page=${state.page}${state.filter ? `&filter=${state.filter}` : ''}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
      });

      if (response.status === 400) {
        throw new Error('Incorrect data');
      }

      if (response.status === 200) {
        const { data: { tickets } } = await response.json();
        commit('setTickets', tickets);
      }
    },
    async updateTicketStatus({ commit, getters }, ticket) {
      const response = await fetch(`${baseUrl}/ticket/${ticket.id}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getters.getCredentialString,
        },
        body: JSON.stringify({
          status: ticket.status,
        }),
      });

      if (response.status === 204) {
        commit('changeTicket', ticket);
      }
    },
  },
};

export default ticketsView;

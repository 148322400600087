<template>
    <div class="block">
        <nav v-if="callingSummary" class="level">
            <div class="box level-item has-text-centered has-background-success-light">
                <div class="columns is-vcentered">
                  <div class="column is-3">
                    <span class="icon has-text-success title">
                      <i class="fas fa-pound-sign"></i>
                    </span>
                  </div>
                  <div class="column is-10">
                    <p class="heading">Total Sale</p>
                    <p class="title">£{{ callingSummary.total }}</p>
                  </div>
                </div>
            </div>
            <div class="box level-item has-text-centered has-background-info-light">
                <div class="columns is-vcentered">
                  <div class="column is-3">
                    <span class="icon has-text-info title">
                      <i class="fas fa-shopping-cart"></i>
                    </span>
                  </div>
                  <div class="column is-10">
                    <p class="heading">Orders</p>
                    <p class="title">{{ callingSummary.orderCount }}</p>
                  </div>
                </div>
            </div>
            <div class="box level-item has-text-centered has-background-danger-light">
                <div class="columns is-vcentered">
                  <div class="column is-3">
                    <span class="icon has-text-danger title">
                      <i class="fas fa-tag"></i>
                    </span>
                  </div>
                  <div class="column is-10">
                    <p class="heading">Open Tickets</p>
                    <p class="title">{{ callingSummary.openTickets }}</p>
                  </div>
                </div>
            </div>
            <div class="box level-item has-text-centered has-background-warning-light">
                <div class="columns is-vcentered">
                  <div class="column is-3">
                    <span class="icon has-text-warning title">
                      <i class="fas fa-calendar-alt"></i>
                    </span>
                  </div>
                  <div class="column is-10">
                    <p class="heading">Callbacks</p>
                    <p class="title">{{ callingSummary.callbacks }}</p>
                  </div>
                </div>
            </div>
        </nav>
        <div class="columns mt-5">
            <div class="column is-5">
                <div class="block-custom" v-if="chartData.length">
                  <b-taglist attached>
                      <span
                      v-on:click="updateDurationFilter('week')"
                      >
                        <b-tag class="is-clickable"
                          :type="paymentChartFilter === 'week' ? 'is-success' : ''">
                          Weekly
                        </b-tag>
                      </span>
                      <span
                      v-on:click="updateDurationFilter('month')"
                      >
                        <b-tag class="is-clickable"
                          :type="paymentChartFilter === 'month' ? 'is-success' : ''">
                        Monthly
                        </b-tag>
                      </span>
                  </b-taglist>
                  <span v-if="dataCollection">
                    <PaymentChart :chart-data="dataCollection" />
                  </span>
                </div>
            </div>
            <div class="column is-7 mt-5">
                <OrderList stubbed />
            </div>
        </div>
    </div>
</template>

<script>
import OrderList from '@/components/OrderList/OrderList.vue';
import PaymentChart from '@/components/PaymentChart';
import { mapState, mapActions } from 'vuex';

export default {
  data: () => ({
    dataCollection: null,
  }),
  methods: {
    ...mapActions([
      'getCallingChartData',
      'setPaymentChartFilter',
    ]),
    async updateDurationFilter(filter) {
      await this.setPaymentChartFilter(filter);
      this.fillData();
    },
    fillData() {
      this.dataCollection = {
        labels: this.paymentLabels,
        datasets: [
          {
            label: this.paymentChartFilter === 'week' ? 'Payments this week' : 'Payments this month',
            data: this.chartData,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: '#FEB1C1',
            tension: 0.1,
          },
        ],
      };
    },
  },
  computed: {
    ...mapState({
      chartData: ({ home: { chartData } }) => chartData,
      paymentChartFilter: ({ home: { paymentChartFilter } }) => paymentChartFilter,
      callingSummary: ({ home: { callingSummary } }) => callingSummary,
      paymentLabels: ({ home: { paymentLabels } }) => paymentLabels,
    }),
  },
  async mounted() {
    await this.getCallingChartData();
    this.fillData();
  },
  components: {
    OrderList,
    PaymentChart,
  },
};
</script>

<style scoped>

.level > .level-item.box {
  margin: 1em;
}

.block-custom {
  margin: 1.9em;
}
</style>

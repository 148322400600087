import { baseUrl } from '@/common';

const oneDayCourseView = {
  state: () => ({
    oneDayCourses: [],
    page: 1,
    filter: null,
    sort: 'updatedAt',
    status: null,
  }),
  mutations: {
    setOneDayCourses(state, oneDayCourses) {
      state.oneDayCourses = oneDayCourses;
    },
    setOneDayCoursesViewPage(state, page) {
      state.page = page;
    },
    setOneDayCoursesViewFilter(state, filter) {
      state.filter = filter;
    },
    setOneDayCoursesViewSort(state, sort) {
      state.sort = sort;
    },
    setOneDayCoursesViewFilterStatus(state, status) {
      state.status = status;
    },
  },
  actions: {
    setOneDayCoursesViewFilter({ commit }, payload) {
      commit('setOneDayCoursesViewFilter', payload);
    },
    setOneDayCoursesViewSort({ commit }, payload) {
      commit('setOneDayCoursesViewSort', payload);
    },
    setOneDayCoursesViewFilterStatus({ commit }, payload) {
      commit('setOneDayCoursesViewFilterStatus', payload);
    },
    updateOnlineCoursesViewPage({ commit, state }, payload) {
      if (payload === 'INCREMENT') {
        commit('setOneDayCoursesViewPage', state.page + 1);
      } else if (payload === 'DECREMENT') {
        if (state.page > 1) {
          commit('setOneDayCoursesViewPage', state.page - 1);
        } else {
          commit('setOneDayCoursesViewPage', 1);
        }
      } else if (typeof payload === 'number') {
        commit('setOneDayCoursesViewPage', payload);
      }
    },
    async queryOneDayCourses({ state, getters, commit }) {
      const response = await fetch(
        `${baseUrl}/course/offline?page=${state.page}&sort=${state.sort}${state.filter ? `&filter=${state.filter}` : ''}${state.status ? `&status=${state.status}` : ''}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getters.getCredentialString,
          },
        },
      );

      if (response.status === 400) {
        throw new Error('Incorrect data');
      }

      if (response.status === 200) {
        const { data: { oneDayCourses } } = await response.json();
        commit('setOneDayCourses', oneDayCourses);
      }
    },
  },
};

export default oneDayCourseView;

<template>
    <section>
        <b-skeleton width="20%" :animated="animated"></b-skeleton>
        <b-skeleton width="30%" :animated="animated"></b-skeleton>
        <b-skeleton width="40%" :animated="animated"></b-skeleton>

        <b-skeleton width="50%" :animated="animated"></b-skeleton>
        <b-skeleton width="60%" :animated="animated"></b-skeleton>
        <b-skeleton width="70%" :animated="animated"></b-skeleton>

        <b-skeleton width="80%" :animated="animated"></b-skeleton>
        <b-skeleton width="90%" :animated="animated"></b-skeleton>

        <b-skeleton :animated="animated"></b-skeleton>

        <br>
        <br>

        <b-skeleton width="20%" :animated="animated"></b-skeleton>
        <b-skeleton width="30%" :animated="animated"></b-skeleton>
        <b-skeleton width="40%" :animated="animated"></b-skeleton>

        <b-skeleton width="50%" :animated="animated"></b-skeleton>
        <b-skeleton width="60%" :animated="animated"></b-skeleton>
        <b-skeleton width="70%" :animated="animated"></b-skeleton>

        <b-skeleton width="80%" :animated="animated"></b-skeleton>
        <b-skeleton width="90%" :animated="animated"></b-skeleton>

        <b-skeleton :animated="animated"></b-skeleton>

        <br>
        <br>

        <b-skeleton width="20%" :animated="animated"></b-skeleton>
        <b-skeleton width="30%" :animated="animated"></b-skeleton>
        <b-skeleton width="40%" :animated="animated"></b-skeleton>

        <b-skeleton width="50%" :animated="animated"></b-skeleton>
        <b-skeleton width="60%" :animated="animated"></b-skeleton>
        <b-skeleton width="70%" :animated="animated"></b-skeleton>

        <b-skeleton width="80%" :animated="animated"></b-skeleton>
        <b-skeleton width="90%" :animated="animated"></b-skeleton>

        <b-skeleton :animated="animated"></b-skeleton>

    </section>
</template>

<script>
export default {
  data() {
    return {
      animated: true,
    };
  },
};
</script>

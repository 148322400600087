<template>
  <b-field id="lead-search">
    <b-autocomplete
      :data="data"
      placeholder="Search by name, email or phone number"
      type="search"
      :loading="isFetching"
      @typing="getAsyncData"
      @select="updateSelectedUser"
    >
      <template slot-scope="props">
        <div class="media" id="lead-search-results">
          <div class="media-content">
            <span class="mr-4">
              <b-tag v-bind:class="getClassByStatus(props.option.status)" rounded>
                {{ props.option.status }}
              </b-tag>
            </span>
            <strong>Name: </strong
            >{{
              `${props.option.title}
              ${props.option.firstName}
              ${props.option.middleName || ''}
              ${props.option.lastName}`
            }}
            <span class="is-pulled-right">
              <strong>ID:</strong> {{ props.option.id.substring(0, 5) }}
            </span>
            <br />
            <small>
              <strong>Email:</strong> {{ props.option.email }}
              {{ props.option.alternateEmail || '' }}
              <strong>Phone Number:</strong> {{ props.option.phoneNumber }}
              <span class="is-pulled-right">
              {{ new Date(props.option.createdAt).toDateString() }}
            </span>
            </small>
          </div>
        </div>
      </template>
      <template slot="footer">
        <router-link to="/candidate/new">
            <span class="icon">
              <i class="fas fa-plus-circle"></i>
            </span>
            Add New
        </router-link>
      </template>
      <template v-if="isFetching" slot="empty"> Searching... </template>
      <template v-else slot="empty"> No result found </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapActions, mapGetters } from 'vuex';
import { baseUrl, getClassByStatus } from '@/common';

export default {
  data() {
    return {
      data: [],
      selected: null,
      isFetching: false,
    };
  },
  computed: {
    ...mapGetters(['getCredentialString']),
  },
  methods: {
    ...mapActions(['setUserId']),
    getClassByStatus,
    updateSelectedUser({ id }) {
      this.$router.push({ name: 'LeadDetail', params: { userId: id } });
    },
    searchKey(name) {
      return fetch(`${baseUrl}/search?key=${name}`, {
        headers: {
          Authorization: this.getCredentialString,
        },
      })
        .then((response) => {
          if (response.status === 204 || response.status === 400) {
            throw new Error('No results found');
          }

          return response;
        })
        .then((response) => response.json());
    },
    getAsyncData: debounce(function getAsyncData(name) {
      this.data = [];
      const trimmedSearchTerm = name.trim();
      if (!trimmedSearchTerm.length || trimmedSearchTerm.length < 3) {
        return;
      }
      this.isFetching = true;
      this.searchKey(trimmedSearchTerm)
        .then(({ data }) => {
          data.users.forEach((item) => this.data.push(item));
        })
        .catch(() => {
          this.data = [];
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 1200),
  },
};
</script>

<style scoped>
section {
  margin-top: 2.5em;
}
.mr-4 {
  margin-right: 2em;
}

.field {
  min-width: 500px;
}
</style>
